import { Link } from "react-router-dom";
const Submit = () => {
    return ( 
        <div className="submit">
            <h2> Form Submitted Successfully! </h2>
           
        </div>
     );
}
 
export default Submit;