import { useEffect, useState } from "react";
import { useNavigate  } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container'
import { ReactMediaRecorder } from "react-media-recorder";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import { Alert } from 'react'
import DetectRTC from 'detectrtc'
import Statement from "./Statement";






 const Survey2 = () => {

    

    
    const [searchParams, setSearchParams] = useSearchParams();

    const [P_ID,setPID] = useState(searchParams.get("userID"));
   

    // Recorder

    const [audioURL,setAudioURL] = useState('')   // Aws URL for audioFile
    const [selectedFile, setSelectedFile] = useState('');  //Audio File selection


    const [isPending, setIsPending] = useState(false)  //Check if the all requests are done before proceeding to next page.

   

    // Date for file name
    const [curDate,setCurDate] = useState('')  
    


    const navigate = useNavigate(); // Navigate to switch pages

    const [minAudio, setMinAudio] = useState(false) 


    // Function which generates presigned url from backend (POST), and then uploads audio to that url (PUT)

    async  function handleAudio (name,audioFile) {
     
        fetch("/audio",{
          method : 'POST',
          headers : { 
            'Content-Type': 'application/json'
        },
          body : JSON.stringify({fileName:name,fileType:"audio/wav"})
        }).then((res) =>{ 
          if(res.ok){
            return res.json()
          }
          else
           throw new Error ("Something went wrong")
          }).then((data)=>{
        fetch(data.signedUrl,{
          method : 'PUT',
          body : audioFile
        }).then((res)=>{let link = new URL(res.url)
          setAudioURL(link.origin+"/"+name)})
        }).catch((error)=>{
          console.log(error)
        })
        return;
    }

    
    // On Form Submission
    const handleSubmit = async (e) =>{
      e.preventDefault();
  
        
        handleAudio(P_ID+"-"+curDate,selectedFile)
    }


    // UseEffect which runs once we have the file uploaded to S3, updates the DB
    useEffect(()=>{
      function finalpost(){
        
        const answers = {P_ID,audioURL,duration,curDate}
        console.log(answers.audioURL)
        setIsPending(true);
        axios.post("/answers2",  answers   ).then(res=>{
            console.log(res)
            console.log('details added');
            setIsPending(false);
            alert("Form Submitted")
            window.open("https://app.prolific.co/submissions/complete?cc=C1GCHORI","_self")
        })
      }
      if(audioURL!==""){
        
        finalpost()
      
      }
    },[audioURL]); // eslint-disable-next-line

    /// To check if Submit button can be enabled
  
    function SubmitButton(){
      if (duration >= 30 && minAudio){
        return <Button className= "m-4" variant="primary"  type="submit">Submit</Button>
      } else {
        return <Button className= "m-4" type="submit" disabled>Submit</Button>
      };
    };


    // Change recording button text and colour
    
    const [buttonText,setButtonText] = useState("Start Recording")

       async function audioButtonClick(e,start,stop,clearUrl){
      if(buttonText=== "Start Recording" || buttonText === "Start Recording Again"){
        await start()
        console.log("hello")
        e.target.className = "btn btn-danger"
        setButtonText("Stop Recording")
        DetectRTC.load(() => {
          if(!DetectRTC.isWebsiteHasMicrophonePermissions){
            alert("Please allow microphone access in your web browser settings.")
            setButtonText("Start Recording")
            e.target.className = "btn btn-success"
            // e.target.disabled = true
          }
        });
      }
      else{
        stop()
        handlePause()
     
        if((duration) < 30){
        alert("Audio recording must be longer than 30 seconds. Please record again.");
          setSelectedFile("")
          handleReset()
          clearUrl()
          setButtonText("Start Recording")
          e.target.className = "btn btn-success"
        }
        else if ((duration) > 500){
          alert("Audio recording is too long, please record the passage again.");
          setSelectedFile("")
          handleReset()
          clearUrl()
          setButtonText("Start Recording")
          e.target.className = "btn btn-success"
        }
        else{
        setMinAudio(true)
        setButtonText("Recording Completed")
        var currentdate = new Date().toISOString()
        currentdate= currentdate.replaceAll(":","-").split('.')[0] + 'Z';
        setCurDate(currentdate)
        e.target.className = "btn btn-success"
        e.target.disabled = true
        }
      }

    }
    


    // Timer Functions and state


  const [duration, setDuration] = useState(0);
  const [intervalID, setIntervalID] = useState(0);
  const handleStart = () => {
    if (!intervalID) {
      let interval = setInterval(
        () => setDuration((prevCount) => prevCount + 1),
        1000
      );
      setIntervalID(interval);
    }
  };

  const handlePause = () => {
    if (intervalID) {
      clearInterval(intervalID);
      setIntervalID(0);
    }
  };
  const handleReset = () => {
    setDuration(0);
    setIntervalID(0)
  };

  
    
    return (  
        <div className="create1">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <h2>Survey</h2>
            </div>
            <Form onSubmit={handleSubmit}>
            <Container >
            <Form.Label className="m-4"><Statement/></Form.Label>
            <Form.Group className="m-4" controlId="formID">
                <Form.Label>1. ID</Form.Label>
                <Form.Control disabled size="lg"  type = "text" placeholder="0000" value = {P_ID} onChange = {(e)=>setPID(e.target.value)} />
                
                 </Form.Group>


        <Form.Group className="m-4" controlId="formAudioRecord">
          <Form.Label>2. You will now be asked to read a passage of text while recording your voice. Please note, your voice recording must be longer than 30 seconds to submit 
                 <br/>
                 <br/>
            <p>
            "You wished to know all about my grandfather. Well, he is nearly ninety-three years old. He
            dresses himself in an ancient black frock coat, usually minus several buttons; yet he still thinks
            as swiftly as ever. A long, flowing beard clings to his chin, giving those who observe him a
            pronounced feeling of the utmost respect. When he speaks his voice is just a bit cracked and
            quivers a trifle. Twice each day he plays skillfully and with zest upon our small organ. Except in
            the winter when the ooze or snow or ice prevents, he slowly takes a short walk in the open air
            each day. We have often urged him to walk more and smoke less, but he always answers,
            “Banana Oil!” Grandfather likes to be modern in his language."
            </p>
          </Form.Label>
              <div>
    <ReactMediaRecorder
      audio
      onStart = {()=>handleStart()}
      onStop={(blobUrl,blob)=>{
        setSelectedFile(blob)
        }
      }
      render={({ status, startRecording, stopRecording, clearBlobUrl }) => (

        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button className = "btn btn-success" onClick={(e)=>{audioButtonClick(e,startRecording,stopRecording,clearBlobUrl)}}>{buttonText}</Button>
        </div>
      )}
    />
  </div>
  </Form.Group>
  <div style={{ display: "flex", justifyContent: "center" }}>
  <Form.Group className="m-2" controlId="time">
                 <Form.Label>{duration}</Form.Label>
                 </Form.Group></div>

  {/* <Form.Group>
    <RecordView/>
  </Form.Group> */}
  <div style={{ display: "flex", justifyContent: "center" }} >
                {!isPending && <SubmitButton/>}
                {isPending && <Button disabled>Adding Details...</Button>}
                </div>
               
                </Container>
                
            </Form>
            
        </div>
    );
 }
  
 export default Survey2;