const Statement = () => {
    return (
        <div>
           <b> Prize draw</b>:
Within this survey, the fastest 500 participants to complete will be invited back to further parts of this multi-part survey.
Participants will be reinvited to further survey sessions via Prolific and reminded via internal Prolific messages.
<p><b>With each submission of a survey the participant will be entered within a prize draw to win a £100 Amazon Voucher.</b></p>
There will be a total of 6 possible surveys to take and up to 3000 total participants taking part in the draw.
You will be notified if you win the draw via Prolific internal mail. Please keep an eye out!
        </div>
      );
}
 
export default Statement;