import { useEffect, useState } from "react";
import { useNavigate  } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container'
import { ReactMediaRecorder } from "react-media-recorder";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import DetectRTC from 'detectrtc'
import Statement from './Statement';



 const SurveyG5 = () => {

    

    const [PageNo,setPageNo]  = useState(1)
    const [searchParams, setSearchParams] = useSearchParams();

    const [P_ID,setPID] = useState(searchParams.get("userID"));

    const [HI,setHI]=useState("")
    const [HIYears,setHIYears] = useState("1")
    const [HICheck,setHICheck] = useState(false)


    /// PHQ-9 Second Page 

    const [PHQ1,setPHQ1] = useState('');
    const [PHQ2,setPHQ2] = useState('');
    const [PHQ3,setPHQ3] = useState('');
    const [PHQ4,setPHQ4] = useState('');
    const [PHQ5,setPHQ5] = useState('');
    const [PHQ6,setPHQ6] = useState('');
    const [PHQ7,setPHQ7] = useState('');
    const [PHQ8,setPHQ8] = useState('');
    const [PHQ9,setPHQ9] = useState('');


    // Recorder

    const [audioURL,setAudioURL] = useState('')   // Aws URL for audioFile
    const [selectedFile, setSelectedFile] = useState('');  //Audio File selection


    const [isPending, setIsPending] = useState(false)  //Check if the all requests are done before proceeding to next page.

   

    // Date for file name
    const [curDate,setCurDate] = useState('')  
    


    const navigate = useNavigate(); // Navigate to switch pages

    const [minAudio, setMinAudio] = useState(false) 

    const LikScale = {
      "":"",
      0:"Not at all",
      1:"Several days",
      2:"More than half the days",
      3:"Nearly every day"
    }


    // Function which generates presigned url from backend (POST), and then uploads audio to that url (PUT)

    async  function handleAudio (name,audioFile) {
     
        fetch("/audio",{
          method : 'POST',
          headers : { 
            'Content-Type': 'application/json'
        },
          body : JSON.stringify({fileName:name,fileType:"audio/wav"})
        }).then((res) =>{ 
          if(res.ok){
            return res.json()
          }
          else
           throw new Error ("Something went wrong")
          }).then((data)=>{
        fetch(data.signedUrl,{
          method : 'PUT',
          body : audioFile
        }).then((res)=>{let link = new URL(res.url)
          setAudioURL(link.origin+"/"+name)})
        }).catch((error)=>{
          console.log(error)
        })
        return;
    }

    
    // On Form Submission
    const handleSubmit = async (e) =>{
      e.preventDefault();
  
        
        handleAudio(P_ID+"-"+curDate,selectedFile)
    }


    // UseEffect which runs once we have the file uploaded to S3, updates the DB
    useEffect(()=>{
      function finalpost(){
        
        const answers = {P_ID,HI,HIYears,PHQ1,PHQ2,PHQ3,PHQ4,PHQ5,PHQ6,PHQ7,PHQ8,PHQ9,audioURL,duration,curDate}
        // console.log(answers.audioURL)
        setIsPending(true);
        axios.post("/answersG5",  answers   ).then(res=>{
            console.log(res)
            console.log('details added');
            setIsPending(false);
            alert("Form Submitted")
            window.open("https://app.prolific.co/submissions/complete?cc=C1GCHORI","_self")
        })
      }
      if(audioURL!==""){
        
        finalpost()
      
      }
    },[audioURL]); // eslint-disable-next-line

    /// To check if Submit button can be enabled
  
    function SubmitButton(){
      if (duration >= 30 && minAudio){
        return <Button className= "m-4" variant="primary"  type="submit">Submit</Button>
      } else {
        return <Button className= "m-4" type="submit" disabled>Submit</Button>
      };
    };


    // Change recording button text and colour
    
    const [buttonText,setButtonText] = useState("Start Recording")

    async function audioButtonClick(e,start,stop,clearUrl){
      if(buttonText=== "Start Recording" || buttonText === "Start Recording Again"){
        await start()
        e.target.className = "btn btn-danger"
        setButtonText("Stop Recording")
        DetectRTC.load(() => {
          if(!DetectRTC.isWebsiteHasMicrophonePermissions){
            alert("Please allow microphone access in your web browser settings.")
            setButtonText("Start Recording")
            e.target.className = "btn btn-success"
            // e.target.disabled = true
          }
        });
      }
      else{
        stop()
        handlePause()
     
        if((duration) < 30){
        alert("Audio recording must be longer than 30 seconds. Please record again.");
          setSelectedFile("")
          handleReset()
          clearUrl()
          setButtonText("Start Recording")
          e.target.className = "btn btn-success"
        }
        else if ((duration) > 500){
          alert("Audio recording is too long, please record the passage again.");
          setSelectedFile("")
          handleReset()
          clearUrl()
          setButtonText("Start Recording")
          e.target.className = "btn btn-success"
        }
        else{
        setMinAudio(true)
        setButtonText("Recording Completed")
        var currentdate = new Date().toISOString()
        currentdate= currentdate.replaceAll(":","-").split('.')[0] + 'Z';
        setCurDate(currentdate)
        e.target.className = "btn btn-success"
        e.target.disabled = true
        }
      }

    }
    


    // Timer Functions and state


  const [duration, setDuration] = useState(0);
  const [intervalID, setIntervalID] = useState(0);
  const handleStart = () => {
    if (!intervalID) {
      let interval = setInterval(
        () => setDuration((prevCount) => prevCount + 1),
        1000
      );
      setIntervalID(interval);
    }
  };

  const handlePause = () => {
    if (intervalID) {
      clearInterval(intervalID);
      setIntervalID(0);
    }
  };
  const handleReset = () => {
    setDuration(0);
    setIntervalID(0)
  };

  function pageChange(){
    setPageNo(PageNo+1)
  }
  function PageNext({check}){
      if (check){
      return <Button className= "m-4" variant="primary"  type="button" onClick = {pageChange}>Next</Button>
    } else {
      return <Button className= "m-4" type="button" disabled>Next</Button>
    };
  };

  useEffect(()=>{
    if(HI==="no"){
      setHIYears(1)
    }
  },[HI])
  useEffect(()=>{
    if(Number(HIYears)>=1 && Number(HIYears)<=60 && Number.isInteger(Number(HIYears))){
        setHICheck(true)
    }
    else{
          setHICheck(false)
    }
    
  },[HIYears])
  useEffect(() => {
    document.body.scrollTo({ top: 0 })
  }, [PageNo])


  useEffect(() => {
    document.body.scrollTo({ top: 0 })
  }, [PageNo])

    
    return (  
        <div className="create1">
             <div style={{ display: "flex", justifyContent: "center" }}>
            <h2>Survey</h2>
            </div>
            <Form onSubmit={handleSubmit}>
            <Container >
              <div style={{ display: PageNo === 1 ? 'block' : 'none' }}>
                <Form.Label className="m-4"><Statement/></Form.Label>
            <Form.Group className="m-4" controlId="formID">
                <Form.Label>1. ID</Form.Label>
                <Form.Control disabled size="lg"  type = "text" placeholder="0000" value = {P_ID} onChange = {(e)=>setPID(e.target.value)} />
                
                 </Form.Group>
         <Form.Group className="m-4" controlId="formHI">
                <Form.Label>2. Have you had a head injury that has caused you to be knocked out/concussed</Form.Label>
                    <div key={`inline-radio`} className="mb-3">
          <Form.Check
            inline
            value = "yes"
            label="Yes"
            name="HI"
            required
            type="radio"
            onChange={(e)=>setHI(e.target.value)}
            id={`inline-radio-1`}
            
          />
          <Form.Check
            inline
            value = "no"
            label="No"
            name="HI"
            type="radio"
            onChange={(e)=>setHI(e.target.value)}
            id={`inline-radio-2`}
            
          />
          
        </div>
                 </Form.Group>
                 <Form.Group className="m-4" controlId="formHIYear">
                <Form.Label style = {{display : (HI==="yes")?"block" : "none"}}>&nbsp;&nbsp;If yes, how long ago (in years)? Minimum = 1, Maximum = 60 </Form.Label>
                <Form.Control size="lg" style = {{display : (HI==="yes")?"block" : "none"}} type = "number" placeholder = "1" min={1} max ={60} value = {HIYears} onChange = {(e)=>{setHIYears(e.target.value)}} />
                 </Form.Group>

        <div style={{ display: "flex", justifyContent: "center" }} >
          <PageNext check = {P_ID && HI && HICheck} />
        </div>
        
        </div>

        <div style={{ display: PageNo === 2 ? 'block' : 'none' }}>
        <Form.Group className="m-4" controlId="formPHQ">
        <Form.Label>3. Over the last 2 weeks, how often have you been bothered by any of the following problems?</Form.Label>
        <Form.Label>Please answer each of the below questions on a scale of 0 - 3, where 0 is “Not at all”, 1 is “Several days”, 2 is “More than half the days”, and 3 is “Nearly every day”.
</Form.Label>
        </Form.Group>
        
        
        <Form.Group className="m-4" controlId="formPHQ1">
                 <Form.Label>i. Little interest or pleasure in doing things</Form.Label>
                    <div key={`inline-radio`} className="mb-3">
          <Form.Check
            inline
            value = "0"
            label="0"
            name="PHQ1"
            required
            type="radio"
            onChange={(e)=>setPHQ1(e.target.value)}
            id={`inline-radio-1`}
            
          />
          <Form.Check
            inline
            value = "1"
            label="1"
            name="PHQ1"
            type="radio"
            onChange={(e)=>setPHQ1(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "2"
            label="2"
            name="PHQ1"
            type="radio"
            onChange={(e)=>setPHQ1(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "3"
            label="3"
            name="PHQ1"
            type="radio"
            onChange={(e)=>setPHQ1(e.target.value)}
            id={`inline-radio-2`}
            
          />
        </div>
        </Form.Group>

        <Form.Group className="m-4" controlId="formPHQ2">
                 <Form.Label> ii. Feeling down, depressed, or hopeless</Form.Label>
                    <div key={`inline-radio`} className="mb-3">
          <Form.Check
            inline
            value = "0"
            label="0"
            name="PHQ2"
            required
            type="radio"
            onChange={(e)=>setPHQ2(e.target.value)}
            id={`inline-radio-1`}
            
          />
          <Form.Check
            inline
            value = "1"
            label="1"
            name="PHQ2"
            type="radio"
            onChange={(e)=>setPHQ2(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "2"
            label="2"
            name="PHQ2"
            type="radio"
            onChange={(e)=>setPHQ2(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "3"
            label="3"
            name="PHQ2"
            type="radio"
            onChange={(e)=>setPHQ2(e.target.value)}
            id={`inline-radio-2`}
            
          />
        </div>
        </Form.Group>


        <Form.Group className="m-4" controlId="formPHQ3">
                 <Form.Label>iii. Trouble falling or staying asleep, or sleeping too much</Form.Label>
                    <div key={`inline-radio`} className="mb-3">
          <Form.Check
            inline
            value = "0"
            label="0"
            name="PHQ3"
            required
            type="radio"
            onChange={(e)=>setPHQ3(e.target.value)}
            id={`inline-radio-1`}
            
          />
          <Form.Check
            inline
            value = "1"
            label="1"
            name="PHQ3"
            type="radio"
            onChange={(e)=>setPHQ3(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "2"
            label="2"
            name="PHQ3"
            type="radio"
            onChange={(e)=>setPHQ3(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "3"
            label="3"
            name="PHQ3"
            type="radio"
            onChange={(e)=>setPHQ3(e.target.value)}
            id={`inline-radio-2`}
            
          />
        </div>
        </Form.Group>


        <Form.Group className="m-4" controlId="formPHQ4">
                 <Form.Label>iv. Feeling tired or having little energy</Form.Label>
                    <div key={`inline-radio`} className="mb-3">
          <Form.Check
            inline
            value = "0"
            label="0"
            name="PHQ4"
            required
            type="radio"
            onChange={(e)=>setPHQ4(e.target.value)}
            id={`inline-radio-1`}
            
          />
          <Form.Check
            inline
            value = "1"
            label="1"
            name="PHQ4"
            type="radio"
            onChange={(e)=>setPHQ4(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "2"
            label="2"
            name="PHQ4"
            type="radio"
            onChange={(e)=>setPHQ4(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "3"
            label="3"
            name="PHQ4"
            type="radio"
            onChange={(e)=>setPHQ4(e.target.value)}
            id={`inline-radio-2`}
            
          />
        </div>
        </Form.Group>



        <Form.Group className="m-4" controlId="formPHQ5">
                 <Form.Label>v. Poor appetite or overeating</Form.Label>
                    <div key={`inline-radio`} className="mb-3">
          <Form.Check
            inline
            value = "0"
            label="0"
            name="PHQ5"
            required
            type="radio"
            onChange={(e)=>setPHQ5(e.target.value)}
            id={`inline-radio-1`}
            
          />
          <Form.Check
            inline
            value = "1"
            label="1"
            name="PHQ5"
            type="radio"
            onChange={(e)=>setPHQ5(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "2"
            label="2"
            name="PHQ5"
            type="radio"
            onChange={(e)=>setPHQ5(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "3"
            label="3"
            name="PHQ5"
            type="radio"
            onChange={(e)=>setPHQ5(e.target.value)}
            id={`inline-radio-2`}
            
          />
        </div>
        </Form.Group>


        <Form.Group className="m-4" controlId="formPHQ6">
                 <Form.Label>vi. Feeling bad about yourself or that you are a failure or have let yourself or your family down</Form.Label>
                    <div key={`inline-radio`} className="mb-3">
          <Form.Check
            inline
            value = "0"
            label="0"
            name="PHQ6"
            required
            type="radio"
            onChange={(e)=>setPHQ6(e.target.value)}
            id={`inline-radio-1`}
            
          />
          <Form.Check
            inline
            value = "1"
            label="1"
            name="PHQ6"
            type="radio"
            onChange={(e)=>setPHQ6(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "2"
            label="2"
            name="PHQ6"
            type="radio"
            onChange={(e)=>setPHQ6(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "3"
            label="3"
            name="PHQ6"
            type="radio"
            onChange={(e)=>setPHQ6(e.target.value)}
            id={`inline-radio-2`}
            
          />
        </div>
        </Form.Group>



        <Form.Group className="m-4" controlId="formPHQ7">
                 <Form.Label>vii. Trouble concentrating on things, such as reading the newspaper or watching television</Form.Label>
                    <div key={`inline-radio`} className="mb-3">
          <Form.Check
            inline
            value = "0"
            label="0"
            name="PHQ7"
            required
            type="radio"
            onChange={(e)=>setPHQ7(e.target.value)}
            id={`inline-radio-1`}
            
          />
          <Form.Check
            inline
            value = "1"
            label="1"
            name="PHQ7"
            type="radio"
            onChange={(e)=>setPHQ7(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "2"
            label="2"
            name="PHQ7"
            type="radio"
            onChange={(e)=>setPHQ7(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "3"
            label="3"
            name="PHQ7"
            type="radio"
            onChange={(e)=>setPHQ7(e.target.value)}
            id={`inline-radio-2`}
            
          />
        </div>
        </Form.Group>



        <Form.Group className="m-4" controlId="formPHQ8">
                 <Form.Label>viii. Moving or speaking so slowly that other people could have noticed. Or the opposite being so figety or restless that you have been moving around a lot more than usual</Form.Label>
                    <div key={`inline-radio`} className="mb-3">
          <Form.Check
            inline
            value = "0"
            label="0"
            name="PHQ8"
            required
            type="radio"
            onChange={(e)=>setPHQ8(e.target.value)}
            id={`inline-radio-1`}
            
          />
          <Form.Check
            inline
            value = "1"
            label="1"
            name="PHQ8"
            type="radio"
            onChange={(e)=>setPHQ8(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "2"
            label="2"
            name="PHQ8"
            type="radio"
            onChange={(e)=>setPHQ8(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "3"
            label="3"
            name="PHQ8"
            type="radio"
            onChange={(e)=>setPHQ8(e.target.value)}
            id={`inline-radio-2`}
            
          />
        </div>
        </Form.Group>



        <Form.Group className="m-4" controlId="formPHQ9">
                 <Form.Label>ix. Thoughts that you would be better off dead, or of hurting yourself</Form.Label>
                    <div key={`inline-radio`} className="mb-3">
          <Form.Check
            inline
            value = "0"
            label="0"
            name="PHQ9"
            required
            type="radio"
            onChange={(e)=>setPHQ9(e.target.value)}
            id={`inline-radio-1`}
            
          />
          <Form.Check
            inline
            value = "1"
            label="1"
            name="PHQ9"
            type="radio"
            onChange={(e)=>setPHQ9(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "2"
            label="2"
            name="PHQ9"
            type="radio"
            onChange={(e)=>setPHQ9(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "3"
            label="3"
            name="PHQ9"
            type="radio"
            onChange={(e)=>setPHQ9(e.target.value)}
            id={`inline-radio-2`}
            
          />
        </div>
        </Form.Group>
        <div style={{ display: "flex", justifyContent: "center" }} >
          <PageNext check = {PHQ1 && PHQ2 && PHQ3 && PHQ4 && PHQ5 && PHQ6 && PHQ7 && PHQ8 && PHQ9} />
        </div>
    </div>
                 <div  style={{ display: PageNo === 3 ? 'block' : 'none' }}> 
        <Form.Group className="m-4" controlId="formAudioRecord">
          <Form.Label>4. You will now be asked to read a passage of text while recording your voice. Please note, your voice recording must be longer than 30 seconds to submit.
                 <br/>
                 <br/>
            <p>
            "Do you like amusement parks? Well, I sure do. To amuse myself, I went twice last spring. My most MEMORABLE moment was riding on the Caterpillar, which is a gigantic rollercoaster high above the ground. When I saw how high the Caterpillar rose into the bright blue sky I knew it was for me. After waiting in line for thirty minutes, I made it to the front where the man measured my height to see if I was tall enough. I gave the man my coins, asked for change, and jumped on the cart. Tick, tick, tick, the Caterpillar climbed slowly up the tracks. It went SO high I could see the parking lot. Boy was I SCARED! I thought to myself, “There’s no turning back now.” People were so scared they screamed as we swiftly zoomed fast, fast, and faster along the tracks. As quickly as it started, the Caterpillar came to a stop. Unfortunately, it was time to pack the car and drive home. That night I dreamt of the wild ride on the Caterpillar. Taking a trip to the amusement park and riding on the Caterpillar was my MOST memorable moment ever!"
            </p>
          </Form.Label>
              <div>
    <ReactMediaRecorder
      audio
      onStart = {()=>handleStart()}
      onStop={(blobUrl,blob)=>{
        setSelectedFile(blob)
        }
      }
      render={({ status, startRecording, stopRecording, clearBlobUrl }) => (

        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button className = "btn btn-success" onClick={(e)=>{audioButtonClick(e,startRecording,stopRecording,clearBlobUrl)}}>{buttonText}</Button>
        </div>
      )}
    />
  </div>
  </Form.Group>
  <div style={{ display: "flex", justifyContent: "center" }}>
  <Form.Group className="m-2" controlId="time">
                 <Form.Label>{duration}</Form.Label>
                 </Form.Group></div>

  {/* <Form.Group>
    <RecordView/>
  </Form.Group> */}
  <div style={{ display: "flex", justifyContent: "center" }} >
                {!isPending && <SubmitButton/>}
                {isPending && <Button disabled>Adding Details...</Button>}
                </div>
                </div>
                </Container>
                
            </Form>
            
        </div>
    );
 }
  
 export default SurveyG5;