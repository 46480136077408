import { useEffect, useState } from "react";
import {  useNavigate  } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container'
import { ReactMediaRecorder } from "react-media-recorder";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import { Alert } from 'react'
import DetectRTC from 'detectrtc'
import Statement from './Statement';
import Caterpiller from "./Caterpiller";
import Iframe from 'react-iframe'

import Codepen from "react-codepen-embed";



const SurveyStress = () => {

    const [PageNo,setPageNo]  = useState(1)
    const [searchParams, setSearchParams] = useSearchParams();
    const [P_ID,setPID] = useState(searchParams.get("userID"));
    const [code,setCode] = useState();
    const [responses,setResponses] = useState();
    const [audioURL,setAudioURL] = useState('')   // Aws URL for audioFile
    const [selectedFile, setSelectedFile] = useState('');  //Audio File selection

    const [audioURL1,setAudioURL1] = useState('')   // Aws URL for audioFile
    const [selectedFile1, setSelectedFile1] = useState('');  //Audio File selection
    const [curDate1,setCurDate1] = useState('')  
    const [minAudio1, setMinAudio1] = useState(false) 
   


    const [isPending, setIsPending] = useState(false)  //Check if the all requests are done before proceeding to next page.

   

    // Date for file name
    const [curDate,setCurDate] = useState('')  

    const [codePen, setCodePen] = useState(<Codepen hash="zYLmvgP" user="geornb" loader = {() => <div><h1>Loading...</h1></div>} defaultTab = "result" preview = "false" height = "600"  />)
    


    const navigate = useNavigate(); // Navigate to switch pages

    const [minAudio, setMinAudio] = useState(false) 

    const [TAS1,setTAS1] = useState('');
    const [TAS3,setTAS3] = useState('');
    const [TAS6,setTAS6] = useState('');
    const [TAS7,setTAS7] = useState('');
    const [TAS9,setTAS9] = useState('');
    const [TAS13,setTAS13] = useState('');
    const [TAS14,setTAS14] = useState('');


    async  function handleAudio (name,audioFile) {
     
        fetch("/audio",{
          method : 'POST',
          headers : { 
            'Content-Type': 'application/json'
        },
          body : JSON.stringify({fileName:name,fileType:"audio/wav"})
        }).then((res) =>{ 
          if(res.ok){
            return res.json()
          }
          else
           throw new Error ("Something went wrong")
          }).then((data)=>{
        fetch(data.signedUrl,{
          method : 'PUT',
          body : audioFile
        }).then((res)=>{let link = new URL(res.url)
          setAudioURL(link.origin+"/"+name)})
        }).catch((error)=>{
            console.log("PUT ERROR!")
          console.log(error)
        })
        return;
    }

    async  function handleAudio1 (name,audioFile) {
     
        fetch("/audio",{
          method : 'POST',
          headers : { 
            'Content-Type': 'application/json'
        },
          body : JSON.stringify({fileName:name,fileType:"audio/wav"})
        }).then((res) =>{ 
          if(res.ok){
            return res.json()
          }
          else
           throw new Error ("Something went wrong")
          }).then((data)=>{
        fetch(data.signedUrl,{
          method : 'PUT',
          body : audioFile
        }).then((res)=>{let link = new URL(res.url)
          setAudioURL1(link.origin+"/"+name)})
        }).catch((error)=>{
            console.log("PUT ERROR!")
          console.log(error)
        })
        return;
    }

    
    // On Form Submission
    const handleSubmit = async (e) =>{
      e.preventDefault();
        handleAudio(P_ID+"-"+curDate+"-before",selectedFile)
        handleAudio1(P_ID+"-"+curDate1+"-after",selectedFile1)
    }


    // UseEffect which runs once we have the file uploaded to S3, updates the DB
    useEffect(()=>{
      function finalpost(){
        const answers = {P_ID,audioURL,duration,curDate,audioURL1,duration1,curDate1,code,TAS1,TAS3,TAS6,TAS7,TAS9,TAS13,TAS14}
        // console.log(answers.audioURL)
        setIsPending(true);
        axios.post("/answersStress",  answers   ).then(res=>{
            console.log(res)
            console.log('details added');
            setIsPending(false);
            alert("Form Submitted")
            window.open("https://app.prolific.co/submissions/complete?cc=CZRZHQES","_self")
        })
      }
      if(audioURL1!=="" && audioURL!==""){
        
        finalpost()
      
      }
    },[audioURL1,audioURL]); // eslint-disable-next-line

    /// To check if Submit button can be enabled
  
    function SubmitButton(){
      if (duration1 >= 0 && minAudio1){
        return <Button className= "m-4" variant="primary"  type="submit">Submit</Button>
      } else {
        return <Button className= "m-4" type="submit" disabled>Submit</Button>
      };
    };


    // Change recording button text and colour
    
    const [buttonText,setButtonText] = useState("Start Recording")
    const [buttonText1,setButtonText1] = useState("Start Recording")

    async function audioButtonClick(e,start,stop,clearUrl){
      if(buttonText=== "Start Recording" || buttonText === "Start Recording Again"){
        await start()
        e.target.className = "btn btn-danger"
        setButtonText("Stop Recording")
        DetectRTC.load(() => {
          if(!DetectRTC.isWebsiteHasMicrophonePermissions){
            alert("Please allow microphone access in your web browser settings.")
            setButtonText("Start Recording")
            e.target.className = "btn btn-success"
            // e.target.disabled = true
          }
        });
      }
      else{
        stop()
        handlePause()
     
       if ((duration) > 500){
          alert("Audio recording is too long, please record the passage again.");
          setSelectedFile("")
          handleReset()
          clearUrl()
          setButtonText("Start Recording")
          e.target.className = "btn btn-success"
        }
        else{
        setMinAudio(true)
        setButtonText("Recording Completed")
        var currentdate = new Date().toISOString()
        currentdate= currentdate.replaceAll(":","-").split('.')[0] + 'Z';
        setCurDate(currentdate)
        e.target.className = "btn btn-success"
        e.target.disabled = true
        }
      }

    }
    
    async function audioButtonClick1(e,start,stop,clearUrl){
      if(buttonText1=== "Start Recording" || buttonText1 === "Start Recording Again"){
        await start()
        e.target.className = "btn btn-danger"
        setButtonText1("Stop Recording")
        DetectRTC.load(() => {
          if(!DetectRTC.isWebsiteHasMicrophonePermissions){
            alert("Please allow microphone access in your web browser settings.")
            setButtonText1("Start Recording")
            e.target.className = "btn btn-success"
            // e.target.disabled = true
          }
        });
      }
      else{
        stop()
        handlePause1()
     
        
         if ((duration) > 500){
          alert("Audio recording is too long, please record the passage again.");
          setSelectedFile1("")
          handleReset1()
          clearUrl()
          setButtonText1("Start Recording")
          e.target.className = "btn btn-success"
        }
        else{
        setMinAudio1(true)
        setButtonText1("Recording Completed")
        var currentdate = new Date().toISOString()
        currentdate= currentdate.replaceAll(":","-").split('.')[0] + 'Z';
        setCurDate1(currentdate)
        e.target.className = "btn btn-success"
        e.target.disabled = true
        }
      }

    }


    // Timer Functions and state


  const [duration, setDuration] = useState(0);
  const [intervalID, setIntervalID] = useState(0);
  const handleStart = () => {
    if (!intervalID) {
      let interval = setInterval(
        () => setDuration((prevCount) => prevCount + 1),
        1000
      );
      setIntervalID(interval);
    }
  };

  const handlePause = () => {
    if (intervalID) {
      clearInterval(intervalID);
      setIntervalID(0);
    }
  };
  const handleReset = () => {
    setDuration(0);
    setIntervalID(0)
  };

  const [duration1, setDuration1] = useState(0);
  const [intervalID1, setIntervalID1] = useState(0);
  const handleStart1 = () => {
    if (!intervalID1) {
      let interval = setInterval(
        () => setDuration1((prevCount) => prevCount + 1),
        1000
      );
      setIntervalID1(interval);
    }
  };

  const handlePause1 = () => {
    if (intervalID1) {
      clearInterval(intervalID1);
      setIntervalID1(0);
    }
  };
  const handleReset1 = () => {
    setDuration1(0);
    setIntervalID1(0)
  };



  function pageChange(){
    setPageNo(PageNo+1)
  }
  function PageNext({check}){
      if (check){
      return <Button className= "m-4" variant="primary"  type="button" onClick = {pageChange}>Next</Button>
    } else {
      return <Button className= "m-4" type="button" disabled>Next</Button>
    };
}
    useEffect(() => {
        document.body.scrollTo({ top: 0 })
      }, [PageNo])

      return (  
        <div className="create1">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <h2>Survey</h2>
            </div>
            <Form onSubmit={handleSubmit}>
            <Container >
            <div style={{ display: PageNo === 1 ? 'block' : 'none' }}>
            <Form.Group className="m-4" controlId="formID">
                <Form.Label> ID</Form.Label>
                <Form.Control disabled size="lg"  type = "text" placeholder="0000" value = {P_ID} onChange = {(e)=>setPID(e.target.value)} />
                
                 </Form.Group>
                 <div style={{ display: "flex", justifyContent: "center" }} >
          <PageNext check = {P_ID} />
          </div>
          </div>

          <div style={{ display: PageNo === 2 ? 'block' : 'none' }}>
            <p>This survey will ask you to record your voice twice and take part in one activity. Instructions for your first recording will follow.</p>
          <div style={{ display: "flex", justifyContent: "center" }} >
          <PageNext check = {1} />
          </div>
          </div>

          <div style={{ display: PageNo === 3 ? 'block' : 'none' }}>
        <Form.Group className="m-4" controlId="formAudioRecord">
          <Form.Label>You will now be asked to read a passage of text while recording your voice. 
                 <br/>
                 <br/>
            <Caterpiller/>
            <br/>
            <br/>
          </Form.Label>
              <div>
    <ReactMediaRecorder
      audio
      onStart = {()=>handleStart()}
      onStop={(blobUrl,blob)=>{
        setSelectedFile(blob)
        }
      }
      render={({ status, startRecording, stopRecording, clearBlobUrl }) => (

        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button className = "btn btn-success" onClick={(e)=>{audioButtonClick(e,startRecording,stopRecording,clearBlobUrl)}}>{buttonText}</Button>
        </div>
      )}
    />
  </div>
  </Form.Group>
  <div style={{ display: "flex", justifyContent: "center" }}>
  <Form.Group className="m-2" controlId="time">
                 <Form.Label>{duration}</Form.Label>
                 </Form.Group></div>
                 <div style={{ display: "flex", justifyContent: "center" }} >
                 <PageNext check = {minAudio} />
          </div>
</div>
<div style={{ display: PageNo === 4 ? 'block' : 'none' }}>



<p>You will now be required to do a small activity. The instructions are provided with it. Upon completion of the activity, please note down the code given in the end and enter it in the field below.</p>
<p>
  <b>Note</b> - Keyboard is required for the activity. </p>

<div>{codePen}</div>
{/* <div style={{ display: "flex", justifyContent: "center" }} >  <a rel="noopener noreferrer" href="https://psycho-tests.com/test/stroop-test" target="_blank">Link To Activity</a> </div> */}


<Form.Group className="m-2" controlId="code">
<Form.Label> Completion Code</Form.Label>
  <Form.Control size="lg"  type = "text"  value = {code} onChange = {(e)=>{setCode(e.target.value)}} />
  </Form.Group>

  <div style={{ display: "flex", justifyContent: "center" }} >
<PageNext check = {code} />
</div>
                </div>
                <div style={{ display: PageNo === 5 ? 'block' : 'none' }}>
<Form.Group className="m-4" controlId="formAudioRecordAfter">
          <Form.Label>You will now be asked to read a passage of text while recording your voice. 
                 <br/>
                 <br/>
            
            <Caterpiller/>
            <br/>
            <br/>
          </Form.Label>
              <div>
    <ReactMediaRecorder
      audio
      onStart = {()=>handleStart1()}
      onStop={(blobUrl,blob)=>{
        setSelectedFile1(blob)
        }
      }
      render={({ status, startRecording, stopRecording, clearBlobUrl }) => (

        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button className = "btn btn-success" onClick={(e)=>{audioButtonClick1(e,startRecording,stopRecording,clearBlobUrl)}}>{buttonText1}</Button>
        </div>
      )}
    />
  </div>
  </Form.Group>
  <div style={{ display: "flex", justifyContent: "center" }}>
  <Form.Group className="m-2" controlId="time">
                 <Form.Label>{duration1}</Form.Label>
                 
                 </Form.Group></div>
                 <div style={{ display: "flex", justifyContent: "center" }} >
                 <PageNext check = {minAudio1} />
          </div>

                 
          </div>
          <div style={{ display: PageNo === 6 ? 'block' : 'none' }}>
        <Form.Group className="m-4" controlId="formTAS">
        <Form.Label>Please answer each of the below questions on a scale of 1 - 5, where 1 is “Strongly disagree”, 2 is “Disagree”, 3 is “Neither agree nor disagree”, 4 is "Agree" and 5 is "Strongly agree".
</Form.Label>
        </Form.Group>
        
        <Form.Group className="m-4" controlId="formTAS1">
                 <Form.Label>i. I am often confused about what emotion I am feeling.</Form.Label>
                    <div key={`inline-radio`} className="mb-3">
          <Form.Check
            inline
            value = "1"
            label="1"
            name="TAS1"
            required
            type="radio"
            onChange={(e)=>setTAS1(e.target.value)}
            id={`inline-radio-1`}
            
          />
          <Form.Check
            inline
            value = "2"
            label="2"
            name="TAS1"
            type="radio"
            onChange={(e)=>setTAS1(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "3"
            label="3"
            name="TAS1"
            type="radio"
            onChange={(e)=>setTAS1(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "4"
            label="4"
            name="TAS1"
            type="radio"
            onChange={(e)=>setTAS1(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "5"
            label="5"
            name="TAS1"
            required
            type="radio"
            onChange={(e)=>setTAS1(e.target.value)}
            id={`inline-radio-1`}
            
          />
        </div>
        </Form.Group>

        <Form.Group className="m-4" controlId="formTAS3">
                 <Form.Label> ii. I have physical sensations that even doctors don’t understand.</Form.Label>
                    <div key={`inline-radio`} className="mb-3">
                    <Form.Check
            inline
            value = "1"
            label="1"
            name="TAS3"
            required
            type="radio"
            onChange={(e)=>setTAS3(e.target.value)}
            id={`inline-radio-1`}
            
          />
          <Form.Check
            inline
            value = "2"
            label="2"
            name="TAS3"
            type="radio"
            onChange={(e)=>setTAS3(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "3"
            label="3"
            name="TAS3"
            type="radio"
            onChange={(e)=>setTAS3(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "4"
            label="4"
            name="TAS3"
            type="radio"
            onChange={(e)=>setTAS3(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "5"
            label="5"
            name="TAS3"
            required
            type="radio"
            onChange={(e)=>setTAS3(e.target.value)}
            id={`inline-radio-1`}
            
          />
        </div>
        </Form.Group>


        <Form.Group className="m-4" controlId="formTAS6">
                 <Form.Label>iii. When I am upset, I don’t know if I am sad, frightened, or angry.</Form.Label>
                    <div key={`inline-radio`} className="mb-3">
                    <Form.Check
            inline
            value = "1"
            label="1"
            name="TAS6"
            required
            type="radio"
            onChange={(e)=>setTAS6(e.target.value)}
            id={`inline-radio-1`}
            
          />
          <Form.Check
            inline
            value = "2"
            label="2"
            name="TAS6"
            type="radio"
            onChange={(e)=>setTAS6(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "3"
            label="3"
            name="TAS6"
            type="radio"
            onChange={(e)=>setTAS6(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "4"
            label="4"
            name="TAS6"
            type="radio"
            onChange={(e)=>setTAS6(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "5"
            label="5"
            name="TAS6"
            required
            type="radio"
            onChange={(e)=>setTAS6(e.target.value)}
            id={`inline-radio-1`}
            
          />
        </div>
        </Form.Group>


        <Form.Group className="m-4" controlId="formTAS7">
                 <Form.Label>iv. I am often puzzled by sensations in my body. </Form.Label>
                    <div key={`inline-radio`} className="mb-3">
                    <Form.Check
            inline
            value = "1"
            label="1"
            name="TAS7"
            required
            type="radio"
            onChange={(e)=>setTAS7(e.target.value)}
            id={`inline-radio-1`}
            
          />
          <Form.Check
            inline
            value = "2"
            label="2"
            name="TAS7"
            type="radio"
            onChange={(e)=>setTAS7(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "3"
            label="3"
            name="TAS7"
            type="radio"
            onChange={(e)=>setTAS7(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "4"
            label="4"
            name="TAS7"
            type="radio"
            onChange={(e)=>setTAS7(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "5"
            label="5"
            name="TAS7"
            required
            type="radio"
            onChange={(e)=>setTAS7(e.target.value)}
            id={`inline-radio-1`}
            
          />
        </div>
        </Form.Group>



        <Form.Group className="m-4" controlId="formTAS9">
                 <Form.Label>v. I have feelings that I can’t quite identify.</Form.Label>
                    <div key={`inline-radio`} className="mb-3">
                    <Form.Check
            inline
            value = "1"
            label="1"
            name="TAS9"
            required
            type="radio"
            onChange={(e)=>setTAS9(e.target.value)}
            id={`inline-radio-1`}
            
          />
          <Form.Check
            inline
            value = "2"
            label="2"
            name="TAS9"
            type="radio"
            onChange={(e)=>setTAS9(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "3"
            label="3"
            name="TAS9"
            type="radio"
            onChange={(e)=>setTAS9(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "4"
            label="4"
            name="TAS9"
            type="radio"
            onChange={(e)=>setTAS9(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "5"
            label="5"
            name="TAS9"
            required
            type="radio"
            onChange={(e)=>setTAS9(e.target.value)}
            id={`inline-radio-1`}
            
          />
        </div>
        </Form.Group>


        <Form.Group className="m-4" controlId="formTAS13">
                 <Form.Label>vi. I don’t know what’s going on inside me.</Form.Label>
                    <div key={`inline-radio`} className="mb-3">
                    <Form.Check
            inline
            value = "1"
            label="1"
            name="TAS13"
            required
            type="radio"
            onChange={(e)=>setTAS13(e.target.value)}
            id={`inline-radio-1`}
            
          />
          <Form.Check
            inline
            value = "2"
            label="2"
            name="TAS13"
            type="radio"
            onChange={(e)=>setTAS13(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "3"
            label="3"
            name="TAS13"
            type="radio"
            onChange={(e)=>setTAS13(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "4"
            label="4"
            name="TAS13"
            type="radio"
            onChange={(e)=>setTAS13(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "5"
            label="5"
            name="TAS13"
            required
            type="radio"
            onChange={(e)=>setTAS13(e.target.value)}
            id={`inline-radio-1`}
            
          />
        </div>
        </Form.Group>



        <Form.Group className="m-4" controlId="formTAS14">
                 <Form.Label>vii. I often don’t know why I am angry.</Form.Label>
                    <div key={`inline-radio`} className="mb-3">
                    <Form.Check
            inline
            value = "1"
            label="1"
            name="TAS14"
            required
            type="radio"
            onChange={(e)=>setTAS14(e.target.value)}
            id={`inline-radio-1`}
            
          />
          <Form.Check
            inline
            value = "2"
            label="2"
            name="TAS14"
            type="radio"
            onChange={(e)=>setTAS14(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "3"
            label="3"
            name="TAS14"
            type="radio"
            onChange={(e)=>setTAS14(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "4"
            label="4"
            name="TAS14"
            type="radio"
            onChange={(e)=>setTAS14(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "5"
            label="5"
            name="TAS14"
            required
            type="radio"
            onChange={(e)=>setTAS14(e.target.value)}
            id={`inline-radio-1`}
            
          />
        </div>
        </Form.Group>
        <div style={{ display: "flex", justifyContent: "center" }} >
                 <div style={{ display: "flex", justifyContent: "center" }} >
                {!isPending && <SubmitButton/>}
                {isPending && <Button disabled>Adding Details...</Button>}
                </div>
               
          </div>
          </div>

  {/* <Form.Group>
    <RecordView/>
  </Form.Group> */}
  

                </Container>
                
            </Form>
            
        </div>
    );
}
export default SurveyStress