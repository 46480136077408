import { Link } from "react-router-dom";
const Home = () => {
    return ( 
        <div className="Home">
            <h3> Sample Surveys</h3>
            <br/>
            <h4> <Link to = "survey1?userID=SampleID">Survey1</Link></h4>
            <h4> <Link to = "survey2?userID=SampleID">Survey2</Link></h4>
            <h4> <Link to = "survey2_1?userID=SampleID">Survey2.1</Link></h4>
            <h4> <Link to = "survey2_2?userID=SampleID">Survey2.2</Link></h4>
            <h4> <Link to = "survey3?userID=SampleID">Survey3</Link></h4>
            <h4> <Link to = "surveyG1?userID=SampleID">Survey-Group1</Link></h4>
            <h4> <Link to = "surveyG4?userID=SampleID">Survey-Group4</Link></h4>
            <h4> <Link to = "surveyG5?userID=SampleID">Survey-Group5</Link></h4>
            <h4> <Link to = "surveyG6?userID=SampleID">Survey-Group6</Link></h4>
            <h4> <Link to = "surveyStress?userID=SampleID">Survey-Stress</Link></h4>
           
        </div>
     );
}
 
export default Home;