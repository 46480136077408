import { Link } from "react-router-dom";
const Navbar = () => {
    return (
        <nav className="navbar">
        <h1>VerSurvey</h1>
        <div className="links">
        </div>
        </nav>
      );
}
 
export default Navbar;