import { useEffect, useState } from "react";
import { useNavigate  } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container'
import { ReactMediaRecorder } from "react-media-recorder";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import DetectRTC from 'detectrtc'
import Statement from './Statement';



 const SurveyG4 = () => {

    

    const [PageNo,setPageNo]  = useState(1)
    const [searchParams, setSearchParams] = useSearchParams();

    const [P_ID,setPID] = useState(searchParams.get("userID"));

    const [HI,setHI]=useState("")
    const [HIYears,setHIYears] = useState("1")
    const [HICheck,setHICheck] = useState(false)


    //TAS20

    const [TAS1,setTAS1] = useState('');
    const [TAS3,setTAS3] = useState('');
    const [TAS6,setTAS6] = useState('');
    const [TAS7,setTAS7] = useState('');
    const [TAS9,setTAS9] = useState('');
    const [TAS13,setTAS13] = useState('');
    const [TAS14,setTAS14] = useState('');

    //MBI
    // const [MBI_B1,setMBI_B1] = useState('');
    // const [MBI_B2,setMBI_B2] = useState('');
    // const [MBI_B3,setMBI_B3] = useState('');
    // const [MBI_B4,setMBI_B4] = useState('');
    // const [MBI_B5,setMBI_B5] = useState('');
    // const [MBI_B6,setMBI_B6] = useState('');
    // const [MBI_B7,setMBI_B7] = useState('');

    // const [MBI_D1,setMBI_D1] = useState('');
    // const [MBI_D2,setMBI_D2] = useState('');
    // const [MBI_D3,setMBI_D3] = useState('');
    // const [MBI_D4,setMBI_D4] = useState('');
    // const [MBI_D5,setMBI_D5] = useState('');
    // const [MBI_D6,setMBI_D6] = useState('');
    // const [MBI_D7,setMBI_D7] = useState('');

    // const [MBI_P1,setMBI_P1] = useState('');
    // const [MBI_P2,setMBI_P2] = useState('');
    // const [MBI_P3,setMBI_P3] = useState('');
    // const [MBI_P4,setMBI_P4] = useState('');
    // const [MBI_P5,setMBI_P5] = useState('');
    // const [MBI_P6,setMBI_P6] = useState('');
    // const [MBI_P7,setMBI_P7] = useState('');
    // const [MBI_P8,setMBI_P8] = useState('');    
   
    // Recorder

    const [audioURL,setAudioURL] = useState('')   // Aws URL for audioFile
    const [selectedFile, setSelectedFile] = useState('');  //Audio File selection


    const [isPending, setIsPending] = useState(false)  //Check if the all requests are done before proceeding to next page.

   

    // Date for file name
    const [curDate,setCurDate] = useState('')  
    


    const navigate = useNavigate(); // Navigate to switch pages

    const [minAudio, setMinAudio] = useState(false) 

    const LikScale = {
      "":"",
      0:"Not at all",
      1:"Several days",
      2:"More than half the days",
      3:"Nearly every day"
    }


    // Function which generates presigned url from backend (POST), and then uploads audio to that url (PUT)

    async  function handleAudio (name,audioFile) {
     
        fetch("/audio",{
          method : 'POST',
          headers : { 
            'Content-Type': 'application/json'
        },
          body : JSON.stringify({fileName:name,fileType:"audio/wav"})
        }).then((res) =>{ 
          if(res.ok){
            return res.json()
          }
          else
           throw new Error ("Something went wrong")
          }).then((data)=>{
        fetch(data.signedUrl,{
          method : 'PUT',
          body : audioFile
        }).then((res)=>{let link = new URL(res.url)
          setAudioURL(link.origin+"/"+name)})
        }).catch((error)=>{
          console.log(error)
        })
        return;
    }

    
    // On Form Submission
    const handleSubmit = async (e) =>{
      e.preventDefault();
  
        
        handleAudio(P_ID+"-"+curDate,selectedFile)
    }


    // UseEffect which runs once we have the file uploaded to S3, updates the DB
    useEffect(()=>{
      function finalpost(){
        
        const answers = {P_ID,HI,HIYears,TAS1,TAS3,TAS6,TAS7,TAS9,TAS13,TAS14,audioURL,duration,curDate}
        // console.log(answers.audioURL)
        setIsPending(true);
        axios.post("/answersG4",  answers   ).then(res=>{
            console.log(res)
            console.log('details added');
            setIsPending(false);
            alert("Form Submitted")
            window.open("https://app.prolific.co/submissions/complete?cc=C1GCHORI","_self")
        })
      }
      if(audioURL!==""){
        
        finalpost()
      
      }
    },[audioURL]); // eslint-disable-next-line

    /// To check if Submit button can be enabled
  
    function SubmitButton(){
      if (duration >= 30 && minAudio){
        return <Button className= "m-4" variant="primary"  type="submit">Submit</Button>
      } else {
        return <Button className= "m-4" type="submit" disabled>Submit</Button>
      };
    };


    // Change recording button text and colour
    
    const [buttonText,setButtonText] = useState("Start Recording")

    async function audioButtonClick(e,start,stop,clearUrl){
      if(buttonText=== "Start Recording" || buttonText === "Start Recording Again"){
        await start()
        e.target.className = "btn btn-danger"
        setButtonText("Stop Recording")
        DetectRTC.load(() => {
          if(!DetectRTC.isWebsiteHasMicrophonePermissions){
            alert("Please allow microphone access in your web browser settings.")
            setButtonText("Start Recording")
            e.target.className = "btn btn-success"
            // e.target.disabled = true
          }
        });
      }
      else{
        stop()
        handlePause()
     
        if((duration) < 30){
        alert("Audio recording must be longer than 30 seconds. Please record again.");
          setSelectedFile("")
          handleReset()
          clearUrl()
          setButtonText("Start Recording")
          e.target.className = "btn btn-success"
        }
        else if ((duration) > 500){
          alert("Audio recording is too long, please record the passage again.");
          setSelectedFile("")
          handleReset()
          clearUrl()
          setButtonText("Start Recording")
          e.target.className = "btn btn-success"
        }
        else{
        setMinAudio(true)
        setButtonText("Recording Completed")
        var currentdate = new Date().toISOString()
        currentdate= currentdate.replaceAll(":","-").split('.')[0] + 'Z';
        setCurDate(currentdate)
        e.target.className = "btn btn-success"
        e.target.disabled = true
        }
      }

    }
    


    // Timer Functions and state


  const [duration, setDuration] = useState(0);
  const [intervalID, setIntervalID] = useState(0);
  const handleStart = () => {
    if (!intervalID) {
      let interval = setInterval(
        () => setDuration((prevCount) => prevCount + 1),
        1000
      );
      setIntervalID(interval);
    }
  };

  const handlePause = () => {
    if (intervalID) {
      clearInterval(intervalID);
      setIntervalID(0);
    }
  };
  const handleReset = () => {
    setDuration(0);
    setIntervalID(0)
  };

  function pageChange(){
    setPageNo(PageNo+1)
  }
  function PageNext({check}){
      if (check){
      return <Button className= "m-4" variant="primary"  type="button" onClick = {pageChange}>Next</Button>
    } else {
      return <Button className= "m-4" type="button" disabled>Next</Button>
    };
  };
  useEffect(()=>{
    if(HI==="no"){
      setHIYears(1)
    }
  },[HI])
  useEffect(()=>{
    if(Number(HIYears)>=1 && Number(HIYears)<=60 && Number.isInteger(Number(HIYears))){
        setHICheck(true)
    }
    else{
          setHICheck(false)
    }
    
  },[HIYears])
  useEffect(() => {
    document.body.scrollTo({ top: 0 })
  }, [PageNo])

    
    return (  
        <div className="create1">
             <div style={{ display: "flex", justifyContent: "center" }}>
            <h2>Survey</h2>
            </div>
            <Form onSubmit={handleSubmit}>
            <Container >
              <div style={{ display: PageNo === 1 ? 'block' : 'none' }}>
                <Form.Label className="m-4"><Statement/></Form.Label>
            <Form.Group className="m-4" controlId="formID">
                <Form.Label>1. ID</Form.Label>
                <Form.Control disabled size="lg"  type = "text" placeholder="0000" value = {P_ID} onChange = {(e)=>setPID(e.target.value)} />
                 </Form.Group>

                 <Form.Group className="m-4" controlId="formHI">
                <Form.Label>2. Have you had a head injury that has caused you to be knocked out/concussed</Form.Label>
                    <div key={`inline-radio`} className="mb-3">
          <Form.Check
            inline
            value = "yes"
            label="Yes"
            name="HI"
            required
            type="radio"
            onChange={(e)=>setHI(e.target.value)}
            id={`inline-radio-1`}
            
          />
          <Form.Check
            inline
            value = "no"
            label="No"
            name="HI"
            type="radio"
            onChange={(e)=>setHI(e.target.value)}
            id={`inline-radio-2`}
            
          />
          
        </div>
                 </Form.Group>
                 <Form.Group className="m-4" controlId="formHIYear">
                <Form.Label style = {{display : (HI==="yes")?"block" : "none"}}>&nbsp;&nbsp;If yes, how long ago (in years)? Minimum = 1, Maximum = 60 </Form.Label>
                <Form.Control size="lg" style = {{display : (HI==="yes")?"block" : "none"}} type = "number" placeholder = "1" min={1} max ={60} value = {HIYears} onChange = {(e)=>{setHIYears(e.target.value)}} />
                 </Form.Group>

        <div style={{ display: "flex", justifyContent: "center" }} >
          <PageNext check = {P_ID && HI && HICheck} />
        </div>
        
        
        </div>

        <div style={{ display: PageNo === 2 ? 'block' : 'none' }}>
        <Form.Group className="m-4" controlId="formTAS">
        <Form.Label>3. Please answer each of the below questions on a scale of 1 - 5, where 1 is “Strongly disagree”, 2 is “Disagree”, 3 is “Neither agree nor disagree”, and 4 is "Agree" and 5 is "Strongly agree".
</Form.Label>
        </Form.Group>
        
        <Form.Group className="m-4" controlId="formTAS1">
                 <Form.Label>i. I am often confused about what emotion I am feeling.</Form.Label>
                    <div key={`inline-radio`} className="mb-3">
          <Form.Check
            inline
            value = "1"
            label="1"
            name="TAS1"
            required
            type="radio"
            onChange={(e)=>setTAS1(e.target.value)}
            id={`inline-radio-1`}
            
          />
          <Form.Check
            inline
            value = "2"
            label="2"
            name="TAS1"
            type="radio"
            onChange={(e)=>setTAS1(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "3"
            label="3"
            name="TAS1"
            type="radio"
            onChange={(e)=>setTAS1(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "4"
            label="4"
            name="TAS1"
            type="radio"
            onChange={(e)=>setTAS1(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "5"
            label="5"
            name="TAS1"
            required
            type="radio"
            onChange={(e)=>setTAS1(e.target.value)}
            id={`inline-radio-1`}
            
          />
        </div>
        </Form.Group>

        <Form.Group className="m-4" controlId="formTAS3">
                 <Form.Label> ii. I have physical sensations that even doctors don’t understand.</Form.Label>
                    <div key={`inline-radio`} className="mb-3">
                    <Form.Check
            inline
            value = "1"
            label="1"
            name="TAS3"
            required
            type="radio"
            onChange={(e)=>setTAS3(e.target.value)}
            id={`inline-radio-1`}
            
          />
          <Form.Check
            inline
            value = "2"
            label="2"
            name="TAS3"
            type="radio"
            onChange={(e)=>setTAS3(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "3"
            label="3"
            name="TAS3"
            type="radio"
            onChange={(e)=>setTAS3(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "4"
            label="4"
            name="TAS3"
            type="radio"
            onChange={(e)=>setTAS3(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "5"
            label="5"
            name="TAS3"
            required
            type="radio"
            onChange={(e)=>setTAS3(e.target.value)}
            id={`inline-radio-1`}
            
          />
        </div>
        </Form.Group>


        <Form.Group className="m-4" controlId="formTAS6">
                 <Form.Label>iii. When I am upset, I don’t know if I am sad, frightened, or angry.</Form.Label>
                    <div key={`inline-radio`} className="mb-3">
                    <Form.Check
            inline
            value = "1"
            label="1"
            name="TAS6"
            required
            type="radio"
            onChange={(e)=>setTAS6(e.target.value)}
            id={`inline-radio-1`}
            
          />
          <Form.Check
            inline
            value = "2"
            label="2"
            name="TAS6"
            type="radio"
            onChange={(e)=>setTAS6(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "3"
            label="3"
            name="TAS6"
            type="radio"
            onChange={(e)=>setTAS6(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "4"
            label="4"
            name="TAS6"
            type="radio"
            onChange={(e)=>setTAS6(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "5"
            label="5"
            name="TAS6"
            required
            type="radio"
            onChange={(e)=>setTAS6(e.target.value)}
            id={`inline-radio-1`}
            
          />
        </div>
        </Form.Group>


        <Form.Group className="m-4" controlId="formTAS7">
                 <Form.Label>iv. I am often puzzled by sensations in my body. </Form.Label>
                    <div key={`inline-radio`} className="mb-3">
                    <Form.Check
            inline
            value = "1"
            label="1"
            name="TAS7"
            required
            type="radio"
            onChange={(e)=>setTAS7(e.target.value)}
            id={`inline-radio-1`}
            
          />
          <Form.Check
            inline
            value = "2"
            label="2"
            name="TAS7"
            type="radio"
            onChange={(e)=>setTAS7(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "3"
            label="3"
            name="TAS7"
            type="radio"
            onChange={(e)=>setTAS7(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "4"
            label="4"
            name="TAS7"
            type="radio"
            onChange={(e)=>setTAS7(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "5"
            label="5"
            name="TAS7"
            required
            type="radio"
            onChange={(e)=>setTAS7(e.target.value)}
            id={`inline-radio-1`}
            
          />
        </div>
        </Form.Group>



        <Form.Group className="m-4" controlId="formTAS9">
                 <Form.Label>v. I have feelings that I can’t quite identify.</Form.Label>
                    <div key={`inline-radio`} className="mb-3">
                    <Form.Check
            inline
            value = "1"
            label="1"
            name="TAS9"
            required
            type="radio"
            onChange={(e)=>setTAS9(e.target.value)}
            id={`inline-radio-1`}
            
          />
          <Form.Check
            inline
            value = "2"
            label="2"
            name="TAS9"
            type="radio"
            onChange={(e)=>setTAS9(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "3"
            label="3"
            name="TAS9"
            type="radio"
            onChange={(e)=>setTAS9(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "4"
            label="4"
            name="TAS9"
            type="radio"
            onChange={(e)=>setTAS9(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "5"
            label="5"
            name="TAS9"
            required
            type="radio"
            onChange={(e)=>setTAS9(e.target.value)}
            id={`inline-radio-1`}
            
          />
        </div>
        </Form.Group>


        <Form.Group className="m-4" controlId="formTAS13">
                 <Form.Label>vi. I don’t know what’s going on inside me.</Form.Label>
                    <div key={`inline-radio`} className="mb-3">
                    <Form.Check
            inline
            value = "1"
            label="1"
            name="TAS13"
            required
            type="radio"
            onChange={(e)=>setTAS13(e.target.value)}
            id={`inline-radio-1`}
            
          />
          <Form.Check
            inline
            value = "2"
            label="2"
            name="TAS13"
            type="radio"
            onChange={(e)=>setTAS13(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "3"
            label="3"
            name="TAS13"
            type="radio"
            onChange={(e)=>setTAS13(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "4"
            label="4"
            name="TAS13"
            type="radio"
            onChange={(e)=>setTAS13(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "5"
            label="5"
            name="TAS13"
            required
            type="radio"
            onChange={(e)=>setTAS13(e.target.value)}
            id={`inline-radio-1`}
            
          />
        </div>
        </Form.Group>



        <Form.Group className="m-4" controlId="formTAS14">
                 <Form.Label>vii. I often don’t know why I am angry.</Form.Label>
                    <div key={`inline-radio`} className="mb-3">
                    <Form.Check
            inline
            value = "1"
            label="1"
            name="TAS14"
            required
            type="radio"
            onChange={(e)=>setTAS14(e.target.value)}
            id={`inline-radio-1`}
            
          />
          <Form.Check
            inline
            value = "2"
            label="2"
            name="TAS14"
            type="radio"
            onChange={(e)=>setTAS14(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "3"
            label="3"
            name="TAS14"
            type="radio"
            onChange={(e)=>setTAS14(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "4"
            label="4"
            name="TAS14"
            type="radio"
            onChange={(e)=>setTAS14(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "5"
            label="5"
            name="TAS14"
            required
            type="radio"
            onChange={(e)=>setTAS14(e.target.value)}
            id={`inline-radio-1`}
            
          />
        </div>
        </Form.Group>
        <div style={{ display: "flex", justifyContent: "center" }} >
          <PageNext check = {TAS1 && TAS3 && TAS6 && TAS6 && TAS7 && TAS9 && TAS13 && TAS14} />
        </div>
        </div>
        {/* <div style={{ display: PageNo === 3 ? 'block' : 'none' }}>
        <Form.Group className="m-4" controlId="formMBI_B">
        <Form.Label>4. Please answer each of the below questions on a scale of 0 - 6, where 0 is "Never", 1 is “A Few Times per Year”, 2 is “Once a Month”, 3 is "A Few Times per Month",4 is "Once a Week", 5 is "A Few Times per Week" and 6 is "Every Day".
</Form.Label>
        </Form.Group>
        <Form.Group className="m-4" controlId="formMBI_B1">
                 <Form.Label>i. I feel emotionally drained by my work.</Form.Label>
                    <div key={`inline-radio`} className="mb-3">
                    <Form.Check
            inline
            value = "0"
            label="0"
            name="MBI_B1"
            required
            type="radio"
            onChange={(e)=>setMBI_B1(e.target.value)}
            id={`inline-radio-1`}
            
          />
                    <Form.Check
            inline
            value = "1"
            label="1"
            name="MBI_B1"
            required
            type="radio"
            onChange={(e)=>setMBI_B1(e.target.value)}
            id={`inline-radio-1`}
            
          />
          <Form.Check
            inline
            value = "2"
            label="2"
            name="MBI_B1"
            type="radio"
            onChange={(e)=>setMBI_B1(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "3"
            label="3"
            name="MBI_B1"
            type="radio"
            onChange={(e)=>setMBI_B1(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "4"
            label="4"
            name="MBI_B1"
            type="radio"
            onChange={(e)=>setMBI_B1(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "5"
            label="5"
            name="MBI_B1"
            required
            type="radio"
            onChange={(e)=>setMBI_B1(e.target.value)}
            id={`inline-radio-1`}
            
          />
            <Form.Check
            inline
            value = "6"
            label="6"
            name="MBI_B1"
            required
            type="radio"
            onChange={(e)=>setMBI_B1(e.target.value)}
            id={`inline-radio-1`}
            
          />
        </div>
</Form.Group>
<Form.Group className="m-4" controlId="formMBI_B2">
                 <Form.Label>ii. Working with people all day long requires a great deal of effort.</Form.Label>
                    <div key={`inline-radio`} className="mb-3">
                    <Form.Check
            inline
            value = "0"
            label="0"
            name="MBI_B2"
            required
            type="radio"
            onChange={(e)=>setMBI_B2(e.target.value)}
            id={`inline-radio-1`}
            
          />
                    <Form.Check
            inline
            value = "1"
            label="1"
            name="MBI_B2"
            required
            type="radio"
            onChange={(e)=>setMBI_B2(e.target.value)}
            id={`inline-radio-1`}
            
          />
          <Form.Check
            inline
            value = "2"
            label="2"
            name="MBI_B2"
            type="radio"
            onChange={(e)=>setMBI_B2(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "3"
            label="3"
            name="MBI_B2"
            type="radio"
            onChange={(e)=>setMBI_B2(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "4"
            label="4"
            name="MBI_B2"
            type="radio"
            onChange={(e)=>setMBI_B2(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "5"
            label="5"
            name="MBI_B2"
            required
            type="radio"
            onChange={(e)=>setMBI_B2(e.target.value)}
            id={`inline-radio-1`}
            
          />
            <Form.Check
            inline
            value = "6"
            label="6"
            name="MBI_B2"
            required
            type="radio"
            onChange={(e)=>setMBI_B2(e.target.value)}
            id={`inline-radio-1`}
            
          />
        </div>
</Form.Group>
<Form.Group className="m-4" controlId="formMBI_B3">
                 <Form.Label>iii. I feel like my work is breaking me down.</Form.Label>
                    <div key={`inline-radio`} className="mb-3">
                    <Form.Check
            inline
            value = "0"
            label="0"
            name="MBI_B3"
            required
            type="radio"
            onChange={(e)=>setMBI_B3(e.target.value)}
            id={`inline-radio-1`}
            
          />
                    <Form.Check
            inline
            value = "1"
            label="1"
            name="MBI_B3"
            required
            type="radio"
            onChange={(e)=>setMBI_B3(e.target.value)}
            id={`inline-radio-1`}
            
          />
          <Form.Check
            inline
            value = "2"
            label="2"
            name="MBI_B3"
            type="radio"
            onChange={(e)=>setMBI_B3(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "3"
            label="3"
            name="MBI_B3"
            type="radio"
            onChange={(e)=>setMBI_B3(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "4"
            label="4"
            name="MBI_B3"
            type="radio"
            onChange={(e)=>setMBI_B3(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "5"
            label="5"
            name="MBI_B3"
            required
            type="radio"
            onChange={(e)=>setMBI_B3(e.target.value)}
            id={`inline-radio-1`}
            
          />
            <Form.Check
            inline
            value = "6"
            label="6"
            name="MBI_B3"
            required
            type="radio"
            onChange={(e)=>setMBI_B3(e.target.value)}
            id={`inline-radio-1`}
            
          />
        </div>
</Form.Group>
<Form.Group className="m-4" controlId="formMBI_B4">
                 <Form.Label>iv. I feel frustrated by my work.</Form.Label>
                    <div key={`inline-radio`} className="mb-3">
                    <Form.Check
            inline
            value = "0"
            label="0"
            name="MBI_B4"
            required
            type="radio"
            onChange={(e)=>setMBI_B4(e.target.value)}
            id={`inline-radio-1`}
            
          />
                    <Form.Check
            inline
            value = "1"
            label="1"
            name="MBI_B4"
            required
            type="radio"
            onChange={(e)=>setMBI_B4(e.target.value)}
            id={`inline-radio-1`}
            
          />
          <Form.Check
            inline
            value = "2"
            label="2"
            name="MBI_B4"
            type="radio"
            onChange={(e)=>setMBI_B4(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "3"
            label="3"
            name="MBI_B4"
            type="radio"
            onChange={(e)=>setMBI_B4(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "4"
            label="4"
            name="MBI_B4"
            type="radio"
            onChange={(e)=>setMBI_B4(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "5"
            label="5"
            name="MBI_B4"
            required
            type="radio"
            onChange={(e)=>setMBI_B4(e.target.value)}
            id={`inline-radio-1`}
            
          />
            <Form.Check
            inline
            value = "6"
            label="6"
            name="MBI_B4"
            required
            type="radio"
            onChange={(e)=>setMBI_B4(e.target.value)}
            id={`inline-radio-1`}
            
          />
        </div>
</Form.Group>
<Form.Group className="m-4" controlId="formMBI_B5">
                 <Form.Label>v. I feel I work too hard at my job.</Form.Label>
                    <div key={`inline-radio`} className="mb-3">
                    <Form.Check
            inline
            value = "0"
            label="0"
            name="MBI_B5"
            required
            type="radio"
            onChange={(e)=>setMBI_B5(e.target.value)}
            id={`inline-radio-1`}
            
          />
                    <Form.Check
            inline
            value = "1"
            label="1"
            name="MBI_B5"
            required
            type="radio"
            onChange={(e)=>setMBI_B5(e.target.value)}
            id={`inline-radio-1`}
            
          />
          <Form.Check
            inline
            value = "2"
            label="2"
            name="MBI_B5"
            type="radio"
            onChange={(e)=>setMBI_B5(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "3"
            label="3"
            name="MBI_B5"
            type="radio"
            onChange={(e)=>setMBI_B5(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "4"
            label="4"
            name="MBI_B5"
            type="radio"
            onChange={(e)=>setMBI_B5(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "5"
            label="5"
            name="MBI_B5"
            required
            type="radio"
            onChange={(e)=>setMBI_B5(e.target.value)}
            id={`inline-radio-1`}
            
          />
            <Form.Check
            inline
            value = "6"
            label="6"
            name="MBI_B5"
            required
            type="radio"
            onChange={(e)=>setMBI_B5(e.target.value)}
            id={`inline-radio-1`}
            
          />
        </div>
</Form.Group>
<Form.Group className="m-4" controlId="formMBI_B6">
                 <Form.Label>vi. It stresses me too much to work in direct contact with people.</Form.Label>
                    <div key={`inline-radio`} className="mb-3">
                    <Form.Check
            inline
            value = "0"
            label="0"
            name="MBI_B6"
            required
            type="radio"
            onChange={(e)=>setMBI_B6(e.target.value)}
            id={`inline-radio-1`}
            
          />
                    <Form.Check
            inline
            value = "1"
            label="1"
            name="MBI_B6"
            required
            type="radio"
            onChange={(e)=>setMBI_B6(e.target.value)}
            id={`inline-radio-1`}
            
          />
          <Form.Check
            inline
            value = "2"
            label="2"
            name="MBI_B6"
            type="radio"
            onChange={(e)=>setMBI_B6(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "3"
            label="3"
            name="MBI_B6"
            type="radio"
            onChange={(e)=>setMBI_B6(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "4"
            label="4"
            name="MBI_B6"
            type="radio"
            onChange={(e)=>setMBI_B6(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "5"
            label="5"
            name="MBI_B6"
            required
            type="radio"
            onChange={(e)=>setMBI_B6(e.target.value)}
            id={`inline-radio-1`}
          />
            <Form.Check
            inline
            value = "6"
            label="6"
            name="MBI_B6"
            required
            type="radio"
            onChange={(e)=>setMBI_B6(e.target.value)}
            id={`inline-radio-1`}
            
          />
        </div>
</Form.Group>
<Form.Group className="m-4" controlId="formMBI_B7">
                 <Form.Label>vii. I feel like I’m at the end of my rope.</Form.Label>
                    <div key={`inline-radio`} className="mb-3">
                    <Form.Check
            inline
            value = "0"
            label="0"
            name="MBI_B7"
            required
            type="radio"
            onChange={(e)=>setMBI_B7(e.target.value)}
            id={`inline-radio-1`}
            
          />
                    <Form.Check
            inline
            value = "1"
            label="1"
            name="MBI_B7"
            required
            type="radio"
            onChange={(e)=>setMBI_B7(e.target.value)}
            id={`inline-radio-1`}
            
          />
          <Form.Check
            inline
            value = "2"
            label="2"
            name="MBI_B7"
            type="radio"
            onChange={(e)=>setMBI_B7(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "3"
            label="3"
            name="MBI_B7"
            type="radio"
            onChange={(e)=>setMBI_B7(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "4"
            label="4"
            name="MBI_B7"
            type="radio"
            onChange={(e)=>setMBI_B7(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "5"
            label="5"
            name="MBI_B7"
            required
            type="radio"
            onChange={(e)=>setMBI_B7(e.target.value)}
            id={`inline-radio-1`}
            
          />
          <Form.Check
            inline
            value = "6"
            label="6"
            name="MBI_B7"
            required
            type="radio"
            onChange={(e)=>setMBI_B7(e.target.value)}
            id={`inline-radio-1`}
          />
        </div>
</Form.Group>
<div style={{ display: "flex", justifyContent: "center" }} >
          <PageNext check = {MBI_B1 && MBI_B2 && MBI_B3 && MBI_B4 && MBI_B5 && MBI_B6 && MBI_B7} />
        </div>
        </div>

        <div style={{ display: PageNo === 4 ? 'block' : 'none' }}>
        <Form.Group className="m-4" controlId="formMBI_D">
        <Form.Label>5. Please answer each of the below questions on a scale of 0 - 6, where 0 is "Never", 1 is “A Few Times per Year”, 2 is “Once a Month”, 3 is "A Few Times per Month",4 is "Once a Week", 5 is "A Few Times per Week" and 6 is "Every Day".
</Form.Label>
        </Form.Group>
        <Form.Group className="m-4" controlId="formMBI_D1">
                 <Form.Label>i. I feel I look after certain patients/clients impersonally, as if they are objects.</Form.Label>
                    <div key={`inline-radio`} className="mb-3">
                    <Form.Check
            inline
            value = "0"
            label="0"
            name="MBI_D1"
            required
            type="radio"
            onChange={(e)=>setMBI_D1(e.target.value)}
            id={`inline-radio-1`}
            
          />
                    <Form.Check
            inline
            value = "1"
            label="1"
            name="MBI_D1"
            required
            type="radio"
            onChange={(e)=>setMBI_D1(e.target.value)}
            id={`inline-radio-1`}
            
          />
          <Form.Check
            inline
            value = "2"
            label="2"
            name="MBI_D1"
            type="radio"
            onChange={(e)=>setMBI_D1(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "3"
            label="3"
            name="MBI_D1"
            type="radio"
            onChange={(e)=>setMBI_D1(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "4"
            label="4"
            name="MBI_D1"
            type="radio"
            onChange={(e)=>setMBI_D1(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "5"
            label="5"
            name="MBI_D1"
            required
            type="radio"
            onChange={(e)=>setMBI_D1(e.target.value)}
            id={`inline-radio-1`}
            
          />
            <Form.Check
            inline
            value = "6"
            label="6"
            name="MBI_D1"
            required
            type="radio"
            onChange={(e)=>setMBI_D1(e.target.value)}
            id={`inline-radio-1`}
            
          />
        </div>
</Form.Group>
<Form.Group className="m-4" controlId="formMBI_D2">
                 <Form.Label>ii. I feel tired when I get up in the morning and have to face another day at work.</Form.Label>
                    <div key={`inline-radio`} className="mb-3">
                    <Form.Check
            inline
            value = "0"
            label="0"
            name="MBI_D2"
            required
            type="radio"
            onChange={(e)=>setMBI_D2(e.target.value)}
            id={`inline-radio-1`}
            
          />
                    <Form.Check
            inline
            value = "1"
            label="1"
            name="MBI_D2"
            required
            type="radio"
            onChange={(e)=>setMBI_D2(e.target.value)}
            id={`inline-radio-1`}
            
          />
          <Form.Check
            inline
            value = "2"
            label="2"
            name="MBI_D2"
            type="radio"
            onChange={(e)=>setMBI_D2(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "3"
            label="3"
            name="MBI_D2"
            type="radio"
            onChange={(e)=>setMBI_D2(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "4"
            label="4"
            name="MBI_D2"
            type="radio"
            onChange={(e)=>setMBI_D2(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "5"
            label="5"
            name="MBI_D2"
            required
            type="radio"
            onChange={(e)=>setMBI_D2(e.target.value)}
            id={`inline-radio-1`}
            
          />
            <Form.Check
            inline
            value = "6"
            label="6"
            name="MBI_D2"
            required
            type="radio"
            onChange={(e)=>setMBI_D2(e.target.value)}
            id={`inline-radio-1`}
            
          />
        </div>
</Form.Group>
<Form.Group className="m-4" controlId="formMBI_D3">
                 <Form.Label>iii. I have the impression that my patients/clients make me responsible for some of their problems.</Form.Label>
                    <div key={`inline-radio`} className="mb-3">
                    <Form.Check
            inline
            value = "0"
            label="0"
            name="MBI_D3"
            required
            type="radio"
            onChange={(e)=>setMBI_D3(e.target.value)}
            id={`inline-radio-1`}
            
          />
                    <Form.Check
            inline
            value = "1"
            label="1"
            name="MBI_D3"
            required
            type="radio"
            onChange={(e)=>setMBI_D3(e.target.value)}
            id={`inline-radio-1`}
            
          />
          <Form.Check
            inline
            value = "2"
            label="2"
            name="MBI_D3"
            type="radio"
            onChange={(e)=>setMBI_D3(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "3"
            label="3"
            name="MBI_D3"
            type="radio"
            onChange={(e)=>setMBI_D3(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "4"
            label="4"setMBI_D3
            name="MBI_D3"
            type="radio"
            onChange={(e)=>setMBI_D3(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "5"
            label="5"
            name="MBI_D3"
            required
            type="radio"
            onChange={(e)=>setMBI_D3(e.target.value)}
            id={`inline-radio-1`}
            
          />
            <Form.Check
            inline
            value = "6"
            label="6"
            name="MBI_D3"
            required
            type="radio"
            onChange={(e)=>setMBI_D3(e.target.value)}
            id={`inline-radio-1`}
            
          />
        </div>
</Form.Group>
<Form.Group className="m-4" controlId="formMBI_D4">
                 <Form.Label>iv. I am at the end of my patience at the end of my work day.</Form.Label>
                    <div key={`inline-radio`} className="mb-3">
                    <Form.Check
            inline
            value = "0"
            label="0"
            name="MBI_D4"
            required
            type="radio"
            onChange={(e)=>setMBI_D4(e.target.value)}
            id={`inline-radio-1`}
            
          />
                    <Form.Check
            inline
            value = "1"
            label="1"
            name="MBI_D4"
            required
            type="radio"
            onChange={(e)=>setMBI_D4(e.target.value)}
            id={`inline-radio-1`}
            
          />
          <Form.Check
            inline
            value = "2"
            label="2"
            name="MBI_D4"
            type="radio"
            onChange={(e)=>setMBI_D4(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "3"
            label="3"
            name="MBI_D4"
            type="radio"
            onChange={(e)=>setMBI_D4(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "4"
            label="4"
            name="MBI_D4"
            type="radio"
            onChange={(e)=>setMBI_D4(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "5"
            label="5"
            name="MBI_D4"
            required
            type="radio"
            onChange={(e)=>setMBI_D4(e.target.value)}
            id={`inline-radio-1`}
            
          />
            <Form.Check
            inline
            value = "6"
            label="6"
            name="MBI_D4"
            required
            type="radio"
            onChange={(e)=>setMBI_D4(e.target.value)}
            id={`inline-radio-1`}
            
          />
        </div>
</Form.Group>
<Form.Group className="m-4" controlId="formMBI_D5">
                 <Form.Label>v. I really don’t care about what happens to some of my patients/clients.</Form.Label>
                    <div key={`inline-radio`} className="mb-3">
                    <Form.Check
            inline
            value = "0"
            label="0"
            name="MBI_D5"
            required
            type="radio"
            onChange={(e)=>setMBI_D5(e.target.value)}
            id={`inline-radio-1`}
            
          />
                    <Form.Check
            inline
            value = "1"
            label="1"
            name="MBI_D5"
            required
            type="radio"
            onChange={(e)=>setMBI_D5(e.target.value)}
            id={`inline-radio-1`}
            
          />
          <Form.Check
            inline
            value = "2"
            label="2"
            name="MBI_D5"
            type="radio"
            onChange={(e)=>setMBI_D5(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "3"
            label="3"
            name="MBI_D5"
            type="radio"
            onChange={(e)=>setMBI_D5(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "4"
            label="4"
            name="MBI_D5"
            type="radio"
            onChange={(e)=>setMBI_D5(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "5"
            label="5"
            name="MBI_D5"
            required
            type="radio"
            onChange={(e)=>setMBI_D5(e.target.value)}
            id={`inline-radio-1`}
            
          />
            <Form.Check
            inline
            value = "6"
            label="6"
            name="MBI_D5"
            required
            type="radio"
            onChange={(e)=>setMBI_D5(e.target.value)}
            id={`inline-radio-1`}
            
          />
        </div>
</Form.Group>
<Form.Group className="m-4" controlId="formMBI_D6">
                 <Form.Label>vi. I have become more insensitive to people since I’ve been working.</Form.Label>
                    <div key={`inline-radio`} className="mb-3">
                    <Form.Check
            inline
            value = "0"
            label="0"
            name="MBI_D6"
            required
            type="radio"
            onChange={(e)=>setMBI_D6(e.target.value)}
            id={`inline-radio-1`}
            
          />
                    <Form.Check
            inline
            value = "1"
            label="1"
            name="MBI_D6"
            required
            type="radio"
            onChange={(e)=>setMBI_D6(e.target.value)}
            id={`inline-radio-1`}
            
          />
          <Form.Check
            inline
            value = "2"
            label="2"
            name="MBI_D6"
            type="radio"
            onChange={(e)=>setMBI_D6(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "3"
            label="3"
            name="MBI_D6"
            type="radio"
            onChange={(e)=>setMBI_D6(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "4"
            label="4"
            name="MBI_D6"
            type="radio"
            onChange={(e)=>setMBI_D6(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "5"
            label="5"
            name="MBI_D6"
            required
            type="radio"
            onChange={(e)=>setMBI_D6(e.target.value)}
            id={`inline-radio-1`}
          />
            <Form.Check
            inline
            value = "6"
            label="6"
            name="MBI_D6"
            required
            type="radio"
            onChange={(e)=>setMBI_D6(e.target.value)}
            id={`inline-radio-1`}
            
          />
        </div>
</Form.Group>
<Form.Group className="m-4" controlId="formMBI_D7">
                 <Form.Label>vii. I’m afraid that this job is making me uncaring.</Form.Label>
                    <div key={`inline-radio`} className="mb-3">
                    <Form.Check
            inline
            value = "0"
            label="0"
            name="MBI_D7"
            required
            type="radio"
            onChange={(e)=>setMBI_D7(e.target.value)}
            id={`inline-radio-1`}
            
          />
                    <Form.Check
            inline
            value = "1"
            label="1"
            name="MBI_D7"
            required
            type="radio"
            onChange={(e)=>setMBI_D7(e.target.value)}
            id={`inline-radio-1`}
            
          />
          <Form.Check
            inline
            value = "2"
            label="2"
            name="MBI_D7"
            type="radio"
            onChange={(e)=>setMBI_D7(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "3"
            label="3"
            name="MBI_D7"
            type="radio"
            onChange={(e)=>setMBI_D7(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "4"
            label="4"
            name="MBI_D7"
            type="radio"
            onChange={(e)=>setMBI_D7(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "5"
            label="5"
            name="MBI_D7"
            required
            type="radio"
            onChange={(e)=>setMBI_D7(e.target.value)}
            id={`inline-radio-1`}
            
          />
          <Form.Check
            inline
            value = "6"
            label="6"
            name="MBI_D7"
            required
            type="radio"
            onChange={(e)=>setMBI_D7(e.target.value)}
            id={`inline-radio-1`}
          />
        </div>
</Form.Group>
<div style={{ display: "flex", justifyContent: "center" }} >
          <PageNext check = {MBI_D1 && MBI_D2 && MBI_D3 && MBI_D4 && MBI_D5 && MBI_D6 && MBI_D7} />
        </div>
        </div>
        <div style={{ display: PageNo === 5 ? 'block' : 'none' }}>
        <Form.Group className="m-4" controlId="formMBI_P">
        <Form.Label>6. Please answer each of the below questions on a scale of 0 - 6, where 0 is "Never", 1 is “A Few Times per Year”, 2 is “Once a Month”, 3 is "A Few Times per Month",4 is "Once a Week", 5 is "A Few Times per Week" and 6 is "Every Day".
</Form.Label>
        </Form.Group>
        <Form.Group className="m-4" controlId="formMBI_P1">
                 <Form.Label>i. I accomplish many worthwhile things in this job.</Form.Label>
                    <div key={`inline-radio`} className="mb-3">
                    <Form.Check
            inline
            value = "0"
            label="0"
            name="MBI_P1"
            required
            type="radio"
            onChange={(e)=>setMBI_P1(e.target.value)}
            id={`inline-radio-1`}
            
          />
                    <Form.Check
            inline
            value = "1"
            label="1"
            name="MBI_P1"
            required
            type="radio"
            onChange={(e)=>setMBI_P1(e.target.value)}
            id={`inline-radio-1`}
            
          />
          <Form.Check
            inline
            value = "2"
            label="2"
            name="MBI_P1"
            type="radio"
            onChange={(e)=>setMBI_P1(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "3"
            label="3"
            name="MBI_P1"
            type="radio"
            onChange={(e)=>setMBI_P1(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "4"
            label="4"
            name="MBI_P1"
            type="radio"
            onChange={(e)=>setMBI_P1(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "5"
            label="5"
            name="MBI_P1"
            required
            type="radio"
            onChange={(e)=>setMBI_P1(e.target.value)}
            id={`inline-radio-1`}
            
          />
            <Form.Check
            inline
            value = "6"
            label="6"
            name="MBI_P1"
            required
            type="radio"
            onChange={(e)=>setMBI_P1(e.target.value)}
            id={`inline-radio-1`}
            
          />
        </div>
</Form.Group>
<Form.Group className="m-4" controlId="formMBI_P2">
                 <Form.Label>ii. I feel full of energy.</Form.Label>
                    <div key={`inline-radio`} className="mb-3">
                    <Form.Check
            inline
            value = "0"
            label="0"
            name="MBI_P2"
            required
            type="radio"
            onChange={(e)=>setMBI_P2(e.target.value)}
            id={`inline-radio-1`}
            
          />
                    <Form.Check
            inline
            value = "1"
            label="1"
            name="MBI_P2"
            required
            type="radio"
            onChange={(e)=>setMBI_P2(e.target.value)}
            id={`inline-radio-1`}
            
          />
          <Form.Check
            inline
            value = "2"
            label="2"
            name="MBI_P2"
            type="radio"
            onChange={(e)=>setMBI_P2(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "3"
            label="3"
            name="MBI_P2"
            type="radio"
            onChange={(e)=>setMBI_P2(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "4"
            label="4"
            name="MBI_P2"
            type="radio"
            onChange={(e)=>setMBI_P2(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "5"
            label="5"
            name="MBI_P2"
            required
            type="radio"
            onChange={(e)=>setMBI_P2(e.target.value)}
            id={`inline-radio-1`}
            
          />
            <Form.Check
            inline
            value = "6"
            label="6"
            name="MBI_P2"
            required
            type="radio"
            onChange={(e)=>setMBI_P2(e.target.value)}
            id={`inline-radio-1`}
            
          />
        </div>
</Form.Group>
<Form.Group className="m-4" controlId="formMBI_P3">
                 <Form.Label>iii. I am easily able to understand what my patients/clients feel.</Form.Label>
                    <div key={`inline-radio`} className="mb-3">
                    <Form.Check
            inline
            value = "0"
            label="0"
            name="MBI_P3"
            required
            type="radio"
            onChange={(e)=>setMBI_P3(e.target.value)}
            id={`inline-radio-1`}
            
          />
                    <Form.Check
            inline
            value = "1"
            label="1"
            name="MBI_P3"
            required
            type="radio"
            onChange={(e)=>setMBI_P3(e.target.value)}
            id={`inline-radio-1`}
            
          />
          <Form.Check
            inline
            value = "2"
            label="2"
            name="MBI_P3"
            type="radio"
            onChange={(e)=>setMBI_P3(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "3"
            label="3"
            name="MBI_P3"
            type="radio"
            onChange={(e)=>setMBI_P3(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "4"
            label="4"
            name="MBI_P3"
            type="radio"
            onChange={(e)=>setMBI_P3(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "5"
            label="5"
            name="MBI_P3"
            required
            type="radio"
            onChange={(e)=>setMBI_P3(e.target.value)}
            id={`inline-radio-1`}
            
          />
            <Form.Check
            inline
            value = "6"
            label="6"
            name="MBI_P3"
            required
            type="radio"
            onChange={(e)=>setMBI_P3(e.target.value)}
            id={`inline-radio-1`}
            
          />
        </div>
</Form.Group>
<Form.Group className="m-4" controlId="formMBI_P4">
                 <Form.Label>iv. I look after my patients’/clients’problems very effectively.</Form.Label>
                    <div key={`inline-radio`} className="mb-3">
                    <Form.Check
            inline
            value = "0"
            label="0"
            name="MBI_P4"
            required
            type="radio"
            onChange={(e)=>setMBI_P4(e.target.value)}
            id={`inline-radio-1`}
            
          />
                    <Form.Check
            inline
            value = "1"
            label="1"
            name="MBI_P4"
            required
            type="radio"
            onChange={(e)=>setMBI_P4(e.target.value)}
            id={`inline-radio-1`}
            
          />
          <Form.Check
            inline
            value = "2"
            label="2"
            name="MBI_P4"
            type="radio"
            onChange={(e)=>setMBI_P4(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "3"
            label="3"
            name="MBI_P4"
            type="radio"
            onChange={(e)=>setMBI_P4(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "4"
            label="4"
            name="MBI_P4"
            type="radio"
            onChange={(e)=>setMBI_P4(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "5"
            label="5"
            name="MBI_P4"
            required
            type="radio"
            onChange={(e)=>setMBI_P4(e.target.value)}
            id={`inline-radio-1`}
            
          />
            <Form.Check
            inline
            value = "6"
            label="6"
            name="MBI_P4"
            required
            type="radio"
            onChange={(e)=>setMBI_P4(e.target.value)}
            id={`inline-radio-1`}
            
          />
        </div>
</Form.Group>
<Form.Group className="m-4" controlId="formMBI_P5">
                 <Form.Label>v. In my work, I handle emotional problems very calmly.</Form.Label>
                    <div key={`inline-radio`} className="mb-3">
                    <Form.Check
            inline
            value = "0"
            label="0"
            name="MBI_P5"
            required
            type="radio"
            onChange={(e)=>setMBI_P5(e.target.value)}
            id={`inline-radio-1`}
            
          />
                    <Form.Check
            inline
            value = "1"
            label="1"
            name="MBI_P5"
            required
            type="radio"
            onChange={(e)=>setMBI_P5(e.target.value)}
            id={`inline-radio-1`}
            
          />
          <Form.Check
            inline
            value = "2"
            label="2"
            name="MBI_P5"
            type="radio"
            onChange={(e)=>setMBI_P5(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "3"
            label="3"
            name="MBI_P5"
            type="radio"
            onChange={(e)=>setMBI_P5(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "4"
            label="4"
            name="MBI_P5"
            type="radio"
            onChange={(e)=>setMBI_P5(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "5"
            label="5"
            name="MBI_P5"
            required
            type="radio"
            onChange={(e)=>setMBI_P5(e.target.value)}
            id={`inline-radio-1`}
            
          />
            <Form.Check
            inline
            value = "6"
            label="6"
            name="MBI_P5"
            required
            type="radio"
            onChange={(e)=>setMBI_P5(e.target.value)}
            id={`inline-radio-1`}
            
          />
        </div>
</Form.Group>
<Form.Group className="m-4" controlId="formMBI_P6">
                 <Form.Label>vi. Through my work, I feel that I have a positive influence on people.</Form.Label>
                    <div key={`inline-radio`} className="mb-3">
                    <Form.Check
            inline
            value = "0"
            label="0"
            name="MBI_P6"
            required
            type="radio"
            onChange={(e)=>setMBI_P6(e.target.value)}
            id={`inline-radio-1`}
            
          />
                    <Form.Check
            inline
            value = "1"
            label="1"
            name="MBI_P6"
            required
            type="radio"
            onChange={(e)=>setMBI_P6(e.target.value)}
            id={`inline-radio-1`}
            
          />
          <Form.Check
            inline
            value = "2"
            label="2"
            name="MBI_P6"
            type="radio"
            onChange={(e)=>setMBI_P6(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "3"
            label="3"
            name="MBI_P6"
            type="radio"
            onChange={(e)=>setMBI_P6(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "4"
            label="4"
            name="MBI_P6"
            type="radio"
            onChange={(e)=>setMBI_P6(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "5"
            label="5"
            name="MBI_P6"
            required
            type="radio"
            onChange={(e)=>setMBI_P6(e.target.value)}
            id={`inline-radio-1`}
          />
            <Form.Check
            inline
            value = "6"
            label="6"
            name="MBI_P6"
            required
            type="radio"
            onChange={(e)=>setMBI_P6(e.target.value)}
            id={`inline-radio-1`}
            
          />
        </div>
</Form.Group>
<Form.Group className="m-4" controlId="formMBI_P7">
                 <Form.Label>vii. I am easily able to create a relaxed atmosphere with my patients/clients.</Form.Label>
                    <div key={`inline-radio`} className="mb-3">
                    <Form.Check
            inline
            value = "0"
            label="0"
            name="MBI_P7"
            required
            type="radio"
            onChange={(e)=>setMBI_P7(e.target.value)}
            id={`inline-radio-1`}
            
          />
                    <Form.Check
            inline
            value = "1"
            label="1"
            name="MBI_P7"
            required
            type="radio"
            onChange={(e)=>setMBI_P7(e.target.value)}
            id={`inline-radio-1`}
            
          />
          <Form.Check
            inline
            value = "2"
            label="2"
            name="MBI_P7"
            type="radio"
            onChange={(e)=>setMBI_P7(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "3"
            label="3"
            name="MBI_P7"
            type="radio"
            onChange={(e)=>setMBI_P7(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "4"
            label="4"
            name="MBI_P7"
            type="radio"
            onChange={(e)=>setMBI_P7(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "5"
            label="5"
            name="MBI_P7"
            required
            type="radio"
            onChange={(e)=>setMBI_P7(e.target.value)}
            id={`inline-radio-1`}
            
          />
          <Form.Check
            inline
            value = "6"
            label="6"
            name="MBI_P7"
            required
            type="radio"
            onChange={(e)=>setMBI_P7(e.target.value)}
            id={`inline-radio-1`}
          />
        </div>
</Form.Group>
<Form.Group className="m-4" controlId="formMBI_P8">
                 <Form.Label>viii. I feel refreshed when I have been close to my patients/clients at work.</Form.Label>
                    <div key={`inline-radio`} className="mb-3">
                    <Form.Check
            inline
            value = "0"
            label="0"
            name="MBI_P8"
            required
            type="radio"
            onChange={(e)=>setMBI_P8(e.target.value)}
            id={`inline-radio-1`}
            
          />
                    <Form.Check
            inline
            value = "1"
            label="1"
            name="MBI_P8"
            required
            type="radio"
            onChange={(e)=>setMBI_P8(e.target.value)}
            id={`inline-radio-1`}
            
          />
          <Form.Check
            inline
            value = "2"
            label="2"
            name="MBI_P8"
            type="radio"
            onChange={(e)=>setMBI_P8(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "3"
            label="3"
            name="MBI_P8"
            type="radio"
            onChange={(e)=>setMBI_P8(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "4"
            label="4"
            name="MBI_P8"
            type="radio"
            onChange={(e)=>setMBI_P8(e.target.value)}
            id={`inline-radio-2`}
            
          />
          <Form.Check
            inline
            value = "5"
            label="5"
            name="MBI_P8"
            required
            type="radio"
            onChange={(e)=>setMBI_P8(e.target.value)}
            id={`inline-radio-1`}
            
          />
          <Form.Check
            inline
            value = "6"
            label="6"
            name="MBI_P8"
            required
            type="radio"
            onChange={(e)=>setMBI_P8(e.target.value)}
            id={`inline-radio-1`}
          />
        </div>
</Form.Group>
<div style={{ display: "flex", justifyContent: "center" }} >
          <PageNext check = {MBI_P1 && MBI_P2 && MBI_P3 && MBI_P4 && MBI_P5 && MBI_P6 && MBI_P7 && MBI_P8} />
        </div>
        </div> */}
 
                 <div  style={{ display: PageNo === 3 ? 'block' : 'none' }}> 
        <Form.Group className="m-4" controlId="formAudioRecord">
          <Form.Label>4. You will now be asked to read a passage of text while recording your voice. Please note, your voice recording must be longer than 30 seconds to submit.
                 <br/>
                 <br/>
            <p>
            "Do you like amusement parks? Well, I sure do. To amuse myself, I went twice last spring. My most MEMORABLE moment was riding on the Caterpillar, which is a gigantic rollercoaster high above the ground. When I saw how high the Caterpillar rose into the bright blue sky I knew it was for me. After waiting in line for thirty minutes, I made it to the front where the man measured my height to see if I was tall enough. I gave the man my coins, asked for change, and jumped on the cart. Tick, tick, tick, the Caterpillar climbed slowly up the tracks. It went SO high I could see the parking lot. Boy was I SCARED! I thought to myself, “There’s no turning back now.” People were so scared they screamed as we swiftly zoomed fast, fast, and faster along the tracks. As quickly as it started, the Caterpillar came to a stop. Unfortunately, it was time to pack the car and drive home. That night I dreamt of the wild ride on the Caterpillar. Taking a trip to the amusement park and riding on the Caterpillar was my MOST memorable moment ever!"
            </p>
          </Form.Label>
              <div>
    <ReactMediaRecorder
      audio
      onStart = {()=>handleStart()}
      onStop={(blobUrl,blob)=>{
        setSelectedFile(blob)
        }
      }
      render={({ status, startRecording, stopRecording, clearBlobUrl }) => (

        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button className = "btn btn-success" onClick={(e)=>{audioButtonClick(e,startRecording,stopRecording,clearBlobUrl)}}>{buttonText}</Button>
        </div>
      )}
    />
  </div>
  </Form.Group>
  <div style={{ display: "flex", justifyContent: "center" }}>
  <Form.Group className="m-2" controlId="time">
                 <Form.Label>{duration}</Form.Label>
                 </Form.Group></div>

  {/* <Form.Group>
    <RecordView/>
  </Form.Group> */}
  <div style={{ display: "flex", justifyContent: "center" }} >
                {!isPending && <SubmitButton/>}
                {isPending && <Button disabled>Adding Details...</Button>}
                </div>
                </div>
                </Container>
                
            </Form>
            
        </div>
    );
 }
  
 export default SurveyG4;