import Navbar from './Navbar';
// import Home from './Home';
import {BrowserRouter as Router , Route, Routes} from 'react-router-dom';
// import Create from './Create';
import 'bootstrap/dist/css/bootstrap.min.css';
import NotFound from './NotFound';
import Submit from './Submit';
import Survey2 from './Survey2';
import Survey1 from './Survey1';
import Home from './Home';
import Survey3 from './Survey3';
import Survey2_1 from './Survey2_1';
import Survey2_2 from './Survey2_2';
import TestSurvey from './TestSurvey';
import SurveyG1 from './SurveyG1';
import SurveyG5 from './SurveyG5';
import SurveyG6 from './SurveyG6';
import SurveyG4 from './SurveyG4';
import SurveyStress from './SurveyStress';


function App() {
  
  // const person = {name:'yoshi',age:30};
  return (
    <Router>
    <div className="App">
      <Navbar />
      <div className="content">
       
      <Routes>
        <Route path="/" element={<Home/>}></Route>
        <Route path="/survey1" element={<Survey1/>}></Route>
        <Route path="/survey2" element={<Survey2/>}></Route>
        <Route path="/survey2_1" element={<Survey2_1/>}></Route>
        <Route path="/survey2_2" element={<Survey2_2/>}></Route>
        <Route path="/survey3" element={<Survey3/>}></Route>
        <Route path="/surveyG1" element={<SurveyG1/>}></Route>
        <Route path="/surveyG4" element={<SurveyG4/>}></Route>
        <Route path="/surveyG5" element={<SurveyG5/>}></Route>
        <Route path="/surveyG6" element={<SurveyG6/>}></Route>
        <Route path="/surveyStress" element={<SurveyStress/>}></Route>
        {/* <Route path="/surveyStressGM" element={<SurveyStressGM/>}></Route> */}
        <Route path="/testsurvey" element={<TestSurvey/>}></Route>
        
        <Route path="/submit" element={<Submit/>}></Route>
        <Route path = "*" element = {<NotFound/>}></Route>
        </Routes>
      </div>
    </div>
    </Router>
  );
}

export default App;
